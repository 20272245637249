import {
  createUserWithEmailAndPassword,
  EmailAuthProvider,
  FacebookAuthProvider,
  getAuth,
  GoogleAuthProvider,
  reauthenticateWithCredential,
  sendEmailVerification,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signInWithPopup,
  signOut,
  updatePassword,
  updateProfile,
  // sendSignInLinkToEmail,
} from "firebase/auth";
import { getDatabase, ref, set, get, child, update } from "firebase/database";
import { isValidPhoneNumber } from "react-phone-number-input";
import { authSliceActions } from "./auth-slice";

export const addUserHandler = (name, useremail, password, number) => {
  return async (dispatch) => {
    const auth = getAuth();
    const database = getDatabase();

    try {
      const check = /((^[0-9]+[a-z]+)|(^[a-z]+[0-9]+))+[0-9a-z]+$/i;

      if (password.length < 8 || !password.match(check)) {
        dispatch(
          authSliceActions.setError({
            error:
              "Password must be longer than 8 characters and must contain both numbers and letters.",
          })
        );
        return false;
      }

      if (!isValidPhoneNumber(number)) {
        dispatch(
          authSliceActions.setError({
            error: "Please enter a valid phone number.",
          })
        );

        return false;
      }

      const res = await createUserWithEmailAndPassword(
        auth,
        useremail,
        password
      );

      if (!res.user) {
        return false;
      }

      // WORKONIT
      await sendEmailVerification(auth.currentUser);

      //   UpdateProfile
      await updateProfile(auth.currentUser, {
        displayName: name,
      });
      // console.log(auth.currentUser);

      // Set user profile in realtime database
      await set(
        ref(database, "users/" + auth.currentUser.uid + "/personalData"),
        {
          name: name,
          email: useremail,
          number: number,
        }
      );

      signOut(auth);

      dispatch(
        authSliceActions.setMessage({
          message:
            "Dear User, check your email for verification. Please check under spam section as well.",
        })
      );

      // For now to stop dispatch from signing in
      return false;

      // console.log(write);

      // const { uid, displayName, email } = auth.currentUser;

      // dispatch(
      //   authSliceActions.signInUser({
      //     id: uid,
      //     name: displayName,
      //     email: email,
      //   })
      // );

      // return true;
    } catch (error) {
      if (error.code === "auth/email-already-in-use") {
        dispatch(
          authSliceActions.setError({
            error: "Email already in use!",
          })
        );
      } else if (error.code === "auth/invalid-email") {
        dispatch(
          authSliceActions.setError({
            error: "Please enter a valid email!",
          })
        );
      }

      // else if (error.code === "auth/weak-password") {
      //   dispatch(
      //     authSliceActions.setError({
      //       error: "Password is too weak!",
      //     })
      //   );
      // }
      else {
        dispatch(
          authSliceActions.setError({
            error: "Some error occurred, please try later.",
          })
        );
      }
      // console.log(error.code);
    }
  };
};

export const loginUserHandler = (useremail, password) => {
  return async (dispatch) => {
    const auth = getAuth();
    try {
      const response = await signInWithEmailAndPassword(
        auth,
        useremail,
        password
      );
      // console.log(response);

      // Check for email verfication
      if (!response.user.emailVerified) {
        dispatch(
          authSliceActions.setMessage({
            message:
              "Dear User, you need to verify your email before signing up!",
          })
        );

        signOut(auth);

        return false;
      }

      const { uid, displayName, email } = response.user;

      try {
        const numberResponse = await get(
          child(ref(getDatabase()), `users/${uid}/personalData/number`)
        );
        var number = numberResponse.val();
      } catch {
        var number = "";
      }

      if (number) {
        localStorage.setItem("number", number);
      }

      dispatch(
        authSliceActions.signInUser({
          id: uid,
          name: displayName,
          email: email,
          number: number,
        })
      );

      dispatch(
        authSliceActions.setError({
          error: "",
        })
      );

      return true;
      // console.log(response);
    } catch (error) {
      if (error.code == "auth/wrong-password") {
        dispatch(
          authSliceActions.setError({
            error: "Wrong email/password.",
          })
        );
      } else if (error.code == "auth/user-not-found") {
        dispatch(
          authSliceActions.setError({
            error: "Email does not exist.",
          })
        );
      } else {
        dispatch(
          authSliceActions.setError({
            error: "Some error occurred, please try again.",
          })
        );
      }

      // console.log(error.code);
    }

    // console.log(response);
  };
};

export const addUserWithGoogleHandler = () => {
  return async (dispatch) => {
    try {
      const provider = new GoogleAuthProvider();
      const auth = getAuth();
      const database = getDatabase();
      const response = await signInWithPopup(auth, provider);

      // const credential = GoogleAuthProvider.credentialFromResult(response);
      // const token = credential.accessToken;
      const { displayName, email, uid } = response.user;

      // Database request
      await set(ref(database, "users/" + uid + "/personalData"), {
        name: displayName,
        email: email,
      });

      // Set data in redux
      dispatch(
        authSliceActions.signInUser({
          id: uid,
          name: displayName,
          email: email,
        })
      );

      dispatch(
        authSliceActions.setError({
          error: "",
        })
      );

      // console.log(response.user);

      return true;
    } catch (error) {
      if (error.code == "auth/popup-closed-by-user") {
        dispatch(
          authSliceActions.setError({
            error: "Google Sign Up cancelled by user!",
          })
        );
      } else {
        dispatch(
          authSliceActions.setError({
            error: "Unknown error occurred, please try later.",
          })
        );
      }
      // console.log(error.code);
    }
  };
};

export const addUserWithFacebookHandler = () => {
  return async (dispatch) => {
    try {
      const provider = new FacebookAuthProvider();
      const auth = getAuth();
      const database = getDatabase();
      const response = await signInWithPopup(auth, provider);

      // INCOMPLETE

      console.log(response);
    } catch (error) {
      console.log(error);
    }
  };
};

export const logoutUserHandler = () => {
  return async (dispatch) => {
    const auth = getAuth();
    try {
      // const response = await signOut(auth);
      await signOut(auth);
      dispatch(authSliceActions.signOutUser());
      dispatch(
        authSliceActions.setError({
          error: "",
        })
      );

      // console.log(response);
      return true;
    } catch (error) {
      // console.log(error.code);
    }
  };
};

export const passwordResetHandler = (email) => {
  return async (dispatch) => {
    const auth = getAuth();

    try {
      await sendPasswordResetEmail(auth, email);

      dispatch(
        authSliceActions.setMessage({
          message: "Email sent! Please check your spam folder as well.",
        })
      );
    } catch (error) {
      if (error.code == "auth/invalid-email") {
        dispatch(
          authSliceActions.setError({
            error: "Email does not exist in our database.",
          })
        );
      } else {
        dispatch(
          authSliceActions.setError({
            error: "An error occurred, please try later.",
          })
        );
      }
      // console.log(error.code);
    }
  };
};

export const setNewUserNameHandler = (name) => {
  return async (dispatch) => {
    try {
      const auth = getAuth();
      const database = getDatabase();

      await updateProfile(auth.currentUser, {
        displayName: name,
      });

      // await set(
      //   ref(database, "users/" + auth.currentUser.uid + "/personalData/"),
      //   {
      //     name: name,
      //   }
      // );

      await update(ref(database), {
        ["users/" + auth.currentUser.uid + "/personalData/name"]: name,
      });

      dispatch(
        authSliceActions.setName({
          name: name,
        })
      );

      dispatch(
        authSliceActions.setMessage({
          message: "Name successfully updated!",
        })
      );

      // console.log("success");

      return true;
    } catch {
      dispatch(
        authSliceActions.setError({
          error: "Some error ocurred, please try later.",
        })
      );
      return false;
    }
  };
};

export const setNewUserNumberHandler = (number) => {
  return async (dispatch) => {
    if (!isValidPhoneNumber(number)) {
      dispatch(
        authSliceActions.setError({
          error: "Please enter a valid phone number.",
        })
      );

      return false;
    }

    try {
      const auth = getAuth();
      const database = getDatabase();

      await update(ref(database), {
        ["users/" + auth.currentUser.uid + "/personalData/number"]: number,
      });

      localStorage.setItem("number", number);

      dispatch(
        authSliceActions.setNumber({
          number: number,
        })
      );

      dispatch(
        authSliceActions.setMessage({
          message: "Number successfully updated!",
        })
      );

      // console.log("success");

      return true;
    } catch {
      dispatch(
        authSliceActions.setError({
          error: "Some error ocurred, please try later.",
        })
      );
      return false;
    }
  };
};

export const setNewUserPasswordHandler = (curPass, newPass) => {
  return async (dispatch) => {
    const auth = getAuth();
    const check = /((^[0-9]+[a-z]+)|(^[a-z]+[0-9]+))+[0-9a-z]+$/i;

    if (newPass.length < 8 || !newPass.match(check)) {
      dispatch(
        authSliceActions.setError({
          error:
            "Password must be longer than 8 characters and must contain both numbers and letters.",
        })
      );
      return false;
    }

    if (newPass === curPass) {
      dispatch(
        authSliceActions.setError({
          error: "Your new password must be different from the old one.",
        })
      );
      return false;
    }

    try {
      const credential = EmailAuthProvider.credential(
        auth.currentUser.email,
        curPass
      );

      await reauthenticateWithCredential(auth.currentUser, credential);

      await updatePassword(auth.currentUser, newPass);

      dispatch(
        authSliceActions.setMessage({
          message: "Password successfully updated!",
        })
      );

      return true;
    } catch (error) {
      if (error.code === "auth/wrong-password") {
        dispatch(
          authSliceActions.setError({
            error: "Wrong Password!",
          })
        );
      } else {
        dispatch(
          authSliceActions.setError({
            error: "Some error ocurred, please try later.",
          })
        );
      }
      return false;
    }
  };
};
