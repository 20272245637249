import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import DollarSign from "@material-ui/icons/MonetizationOn";
import CameraEnhance from "@material-ui/icons/CameraEnhance";
import ThumbsUp from "@material-ui/icons/ThumbUpAlt";
// import Favorite from "@material-ui/icons/Favorite";

import classes from "./Landing.module.css";

const LandingNew = ({ featuresRef, benefitsRef }) => {
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
  const createSignatureLink = isLoggedIn ? "/signature" : "/login";

  const removeActiveClass = () => {
    const active = document.getElementById(classes.active);
    if (active) {
      active.removeAttribute("id");
    }
  };

  return (
    <div className={classes["landing-page"]}>
      {/* Page Start */}

      <div className={classes["initial-display"]}>
        <div>
          <h1 className={classes.heading}>
            {/* EASY EMAIL SIGNATURES TO PROMOTE YOUR BUSINESS */}
            Free Email Signature Generator For Your Business
          </h1>
          <br />

          <div className={classes.text}>
            <p>
              An email signature is a great way to promote your business because
              it is a free marketing tool that all employees can use. It only
              takes a few minutes to set up, and it can reach many people with
              each email sent. Additionally, an email signature gives your
              company a professional look and can help to build trust with
              potential customers.
            </p>
            <span>
              With multiple templates to choose from, EasySignatures.Email makes
              it easy to create an email signature that promotes your business.
              Our templates are designed with branding in mind, so you can be
              sure that your signature will look great and include all the
              information you want to share with recipients.
            </span>
          </div>

          <br />
          <div className={classes.arrow}>
            <Link to={createSignatureLink}>
              <div className={classes["create-sig-button"]}>
                <div>Create Signature</div>
              </div>
            </Link>

            <img
              src="../../../images/arrow.png"
              alt="Arrow Point"
              title="Arrow Point"
            />
          </div>
        </div>

        <div className={classes["initial-image"]}>
          <img
            title="Free Email Signature Generator For Your Business"
            src="../../../images/initialDisplay.png"
            alt="Free Email Signature Generator For Your Business"
          />
        </div>
      </div>

      <div className={classes.support}>
        <h3 className={classes["small-heading"]}>Supported ON</h3>

        <div>
          <img
            src="../../../images/image_7.png"
            title="Free email signatures on Gmail"
            alt="Free email signatures on Gmail"
          />
          <img
            src="../../../images/image_8.png"
            title="Free email signatures on Outlook"
            alt="Free email signatures on Outlook"
          />
          <img
            src="../../../images/image_9.png"
            title="Free email signatures on Apple Mail"
            alt="Free email signatures on Apple Mail"
          />
          <img
            src="../../../images/image_10.png"
            title="Free email signatures on Yahoo"
            alt="Free email signatures on Yahoo"
          />
          <img
            src="../../../images/image_11.png"
            title="Free email signatures on Thunderbird"
            alt="Free email signatures on Thunderbird"
          />
          <img
            src="../../../images/image_12.png"
            title="Free email signatures on Office 365"
            alt="Free email signatures on Office 365"
          />
          <img
            src="../../../images/image_13.jpg"
            title="Free email signatures on Airmail"
            alt="Free email signatures on Airmail"
          />
        </div>
      </div>

      <div className={classes.advantages}>
        <div>
          <img
            src="../../../images/first_adv.png"
            title="Create Professional Email Signatures That Work With Multiple Email Clients"
            alt="Create Professional Email Signatures That Work With Multiple Email Clients"
          />
        </div>
        <div>
          <h2 className={classes["small-heading"]}>
            CREATE PROFESSIONAL EMAIL SIGNATURES THAT WORK WITH MULTIPLE EMAIL
            CLIENTS
          </h2>
          {/* <br /> */}
          <div className={classes.text}>
            <p>
              It can be difficult to create an email signature that looks good
              on all email clients, but EasySignatures.Email makes it easy. Our
              easy-to-use email signature tool makes it easy to create
              professional email signatures for free. Simply select a template,
              enter your information, and download your signature to use in any
              email client.
            </p>
            <p>
              Our email signatures are compatible with all major email clients,
              including Gmail, Outlook, and Apple Mail. So no matter which email
              client you use, your signature will look great.
            </p>
          </div>
          <br />
          <Link to={createSignatureLink}>
            <div className={classes["create-sig-button"]}>
              <div>Create Signature</div>
            </div>
          </Link>
        </div>

        <div className={classes["sec-adv-text"]}>
          <h2 className={classes["small-heading"]}>
            Free Email Signatures For The Entire Company!
          </h2>
          {/* <br /> */}
          <div className={classes.text}>
            <p>
              EasySignatures.Email offers free email signatures for the entire
              company. That’s right, all of your employees can have a
              professional email signature without spending a dime. Simply
              create an account, add your employees, and start creating
              signatures. It’s that easy!
            </p>
            <p>
              You can also customise each team member's signature using
              different templates. This is a great way to add a personal touch
              to each signature and ensure each employee's signature represents
              their role within the company.
            </p>
          </div>
          <br />
          <Link to={createSignatureLink}>
            <div className={classes["create-sig-button"]}>
              <div>Create Signature</div>
            </div>
          </Link>
        </div>
        <div className={classes["sec-adv-img"]}>
          <img
            src="../../../images/four_adv.png"
            alt="Free Email Signatures For The Entire Company!"
            title="Free Email Signatures For The Entire Company!"
          />
        </div>

        {/* <div className={classes["third-adv"]}>
          <img src="../../../images/third_adv.png" alt="third_adv" />
        </div>
        <div>
          <div className={classes["small-heading"]}>
            PROFESSIONAL & DELIVERABILITY FRIENDLY
            <br />
          </div>
          <br />
          <div className={classes["text"]}>
            Email signatures are not just about presenting yourself well - they
            can also impact your deliverability. Make sure you create a
            professional email signature that won't adversely affect your
            ability to reach your recipients. Our easy-to-use email signature
            generator can help you add a polished touch to your emails, without
            compromising your deliverability. Our platform is compatible with
            all major email providers, so you can rest assured that your
            messages will get through. Plus, our signatures are both informative
            and visually appealing, so you can make a lasting impression.
          </div>
          <br />
          <Link to={createSignatureLink}>
            <div className={classes["create-sig-button"]}>
              <div>Create Signature</div>
            </div>
          </Link>
        </div>

        <div className={classes["four-adv-text"]}>
          <div className={classes["small-heading"]}>
            HELPING YOU ADD AN INTUITIVE EMAIL SIGNATURE
          </div>

          <br />
          <div className={classes["text"]}>
            An email signature is a way to make your correspondence more
            professional and easy to remember. Easy Signature makes it simple to
            create an intuitive and attractive email signature in just a few
            minutes. With our easy to use tools, you can make sure your email
            signature is memorable and easy to create. So whether you're using
            Gmail, Outlook, Apple Mail, Thunderbird, or Office 365, make your
            signature stand out with Easy Signature. Add an intuitive and
            sophisticated touch to your emails with Easy Signature. This email
            signature tool makes it easy to add a professional signature to your
            emails with just a few clicks.
          </div>
          <br />
          <Link to={createSignatureLink}>
            <div className={classes["create-sig-button"]}>
              <div>Create Signature</div>
            </div>
          </Link>
        </div>
        <div className={classes["four-adv-img"]}>
          <img src="../../../images/four_adv.png" alt="four_adv" />
        </div> */}
      </div>

      <div ref={featuresRef} className={classes.features}>
        <h2 id="features" className={classes["small-heading"]}>
          WHY YOU SHOULD USE AN EMAIL SIGNATURE FOR BUSINESS CORRESPONDENCE
        </h2>
        {/* <br /> */}
        <div className={classes["group_25"]}>
          <div>
            <div
              onMouseOver={removeActiveClass}
              className={classes["hover-cards"]}
            >
              <span style={{ fontSize: "60px" }}>
                <DollarSign fontSize="inherit" />
              </span>
              <h3>Free Marketing Channel</h3>
              <p className={classes.text}>
                A personalised email signature is an excellent way to advertise
                your products or services. By creating a distinctive and
                appealing email signature, you can encourage customers to visit
                your website or social media accounts to learn more about what
                your business has to offer.
              </p>
            </div>
            <div
              onMouseOver={removeActiveClass}
              id={classes.active}
              className={classes["hover-cards"]}
            >
              <span style={{ fontSize: "60px" }}>
                <CameraEnhance fontSize="inherit" />
              </span>
              <h3>Enhance Your Brand</h3>
              <p className={classes.text}>
                An email signature is a great way to reinforce your company's
                brand. By including your company logo, website address, and
                social media links in your signature, you can help to build name
                recognition for your business. Additionally, using a consistent
                colour scheme and font style in your signature will create a
                professional and cohesive look that reflects well on your
                company.
              </p>
            </div>
            <div
              onMouseOver={removeActiveClass}
              className={classes["hover-cards"]}
            >
              <span style={{ fontSize: "60px" }}>
                <ThumbsUp fontSize="inherit" />
              </span>
              <h3>Build Trust</h3>
              <p className={classes.text}>
                An email signature is a great way to build trust with potential
                customers. By including your contact information, you are making
                it easy for customers to get in touch with you if they have any
                questions or concerns. Additionally, including links to your
                company's website and social media accounts shows that you are
                open and transparent about your business. This can help to build
                trust and confidence in your company.
              </p>
            </div>
            {/* <div className={classes["hover-cards"]}>
              <span>HOLISITC BRAND IMPROVEMENT</span>
              <p className={classes.text}>
                Improve your brand presence by creating email signatures. Email
                signatures are a great way to improve your brand presence and
                make it easy for people to contact you.
              </p>
            </div> */}
          </div>
        </div>
      </div>

      <h2
        style={{ padding: "1rem", textAlign: "center" }}
        ref={benefitsRef}
        id="benefits"
        className={classes["small-heading"]}
      >
        WHO BENEFITS FROM USING A FREE EMAIL SIGNATURE GENERATOR?
      </h2>

      <div className={classes.benefits}>
        <div
          style={{ gridColumn: "1 / span 3" }}
          className={classes["benefits-text"]}
        >
          <h3 className={classes["small-heading"]}>Professionals</h3>
          <br />
          <div className={classes["text"]}>
            If you are a professional, such as a lawyer, doctor, or real estate
            agent, an email signature is a great way to promote your business.
            By including your contact information and website in your signature,
            you can make it easy for potential clients to get in touch with you.
            Additionally, including links to your social media accounts can help
            you to build a following and connect with potential clients.
          </div>
          <br />
          <Link to={createSignatureLink}>
            <div className={classes["create-sig-button"]}>
              <div>Create Signature</div>
            </div>
          </Link>
        </div>
        <div
          style={{ gridColumn: "4 / span 2" }}
          className={classes["benefits-image"]}
        >
          <img
            src="../../../images/fifth_adv.png"
            alt="Professionals"
            title="Professionals"
          />
        </div>

        <div
          style={{ gridColumn: "1 / span 2" }}
          className={classes["benefits-image"]}
        >
          <img
            src="../../../images/six_adv.png"
            alt="Entrepreneurs"
            title="Entrepreneurs"
          />
        </div>
        <div
          style={{ gridColumn: "3 / span 3" }}
          className={`${classes["benefits-text"]} ${classes["sec-adv-img"]}`}
        >
          <h3 className={classes["small-heading"]}>Entrepreneurs</h3>
          <br />
          <div className={classes["text"]}>
            Growing a small business requires marketing it through multiple
            channels to establish a brand identity. One way to communicate these
            values is by ensuring that all email signatures include complete
            contact information, headshots, social media links, and other
            professional details. A consistent and visually appealing sign-off
            helps maintain the established branding.
          </div>
          <br />
          <Link to={createSignatureLink}>
            <div className={classes["create-sig-button"]}>
              <div>Create Signature</div>
            </div>
          </Link>
        </div>

        <div
          style={{ gridColumn: "1 / span 3" }}
          className={classes["benefits-text"]}
        >
          <h3 className={classes["small-heading"]}>
            Marketing/Sales Professionals
          </h3>
          <br />
          <div className={classes["text"]}>
            Email signatures provide an excellent opportunity to market your
            products or services and to establish trustworthiness. By including
            links to your company's website and social media accounts, you can
            encourage customers to learn more about your business. Additionally,
            using a distinctive template for your signature can help to make
            your emails stand out from the crowd.
          </div>
          <br />
          <Link to={createSignatureLink}>
            <div className={classes["create-sig-button"]}>
              <div>Create Signature</div>
            </div>
          </Link>
        </div>
        <div
          style={{ gridColumn: "4 / span 2" }}
          className={classes["benefits-image"]}
        >
          <img
            src="../../../images/sev_adv.png"
            alt="Marketing/Sales Professionals
"
            title="Marketing/Sales Professionals
"
          />
        </div>

        <div
          style={{ gridColumn: "1 / span 2" }}
          className={classes["benefits-image"]}
        >
          <img
            src="../../../images/eight_adv.png"
            alt="Influencers"
            title="Influencers"
          />
        </div>
        <div
          style={{ gridColumn: "3 / span 3" }}
          // className={classes["benefits-text"]}
          className={`${classes["benefits-text"]} ${classes["four-adv-img"]}`}
        >
          <h3 className={classes["small-heading"]}>Influencers</h3>
          <br />
          <div className={classes["text"]}>
            If you're an influencer, you understand how crucial it is to have a
            consistent personal image online. By having a professional email
            signature, not only are you increasing your brand awareness but also
            driving additional traffic to your social media platforms. It's an
            easy way for partners and clients to get in touch with you too! By
            using HTML signatures when sending emails, content creators,
            bloggers, and thought leaders can increase exposure significantly.
          </div>
          <br />
          <Link to={createSignatureLink}>
            <div className={classes["create-sig-button"]}>
              <div>Create Signature</div>
            </div>
          </Link>
        </div>
      </div>

      <div className={classes.information}>
        <h2 style={{ color: "white" }} className={classes["small-heading"]}>
          WOULD YOU LIKE TO BUILD YOUR BRAND ONLINE?
        </h2>

        <div style={{ color: "white" }} className={classes.text}>
          An online email signature maker can help you save time and energy when
          running your business or developing important contacts.
        </div>
        <br />
        <div style={{ color: "white" }} className={classes.text}>
          EasySignature.Email is a free email signature generator that makes it
          easy to create and customise professional email signatures for your
          business correspondence. Using EasySignature.Email, you can add a
          personal touch to each of your emails and promote your products or
          services with ease. Additionally, EasySignature.Email is a great way
          to build trust with potential customers and reinforce your company's
          branding.
        </div>
        <div style={{ color: "white" }} className={classes.text}>
          EasySignature.Email is the best free email signature tool because it
          is easy to use and allows you to customise your email signatures with
          ease. Additionally, EasySignature.Email is a great way to build trust
          with potential customers and reinforce your company's branding.
        </div>
        <div>
          <Link to={createSignatureLink}>
            <div className={classes["create-sig-button-inverse"]}>
              <div>Create Signature</div>
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default LandingNew;
