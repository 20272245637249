import React from "react";

import "./PageNotFound.css";

const PageNotFound = () => {
  return (
    <main className="error404">
      <h1>
        4
        <span style={{ marginLeft: "-20px" }}>
          <i className="fas fa-ghost">0</i>
        </span>
        4
      </h1>
      <h2>Error: 404 page not found</h2>
      <p>Sorry, the page you're looking for cannot be accessed</p>
    </main>
  );
};

export default PageNotFound;
