import React, { useEffect, useState } from "react";
import Signature from "./Signature";
import { resetSignatureData } from "../../store/general-actions";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { getDatabase, ref, child, get, set, remove } from "firebase/database";
import {
  getBlob,
  getStorage,
  uploadBytes,
  ref as sRef,
  deleteObject,
} from "firebase/storage";
import { v4 as uuid } from "uuid";
import { CircularProgress, Fab } from "@mui/material";
// import SearchIcon from "@material-ui/icons/Search";
import CreateIcon from "@material-ui/icons/Create";

import classes from "./Dashboard.module.css";

const Dashboard = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userId = useSelector((state) => state.auth.id);
  const [signatures, setSignatures] = useState([]);
  const [loader, setLoader] = useState({
    loading: false,
    message: "Loading...",
  });

  const duplicateSignatureHandler = async (id) => {
    setLoader({
      loading: true,
      message: "Duplicating...",
    });

    const newSigId = uuid();
    const storage = getStorage();
    const database = getDatabase();

    // Fetch the data
    const responseData = signatures.find((signature) => signature.id === id);
    // console.log(responseData);

    let pathReference = sRef(storage, `images/${userId}/${id}/logo`);
    const logoBlob = await getBlob(pathReference);

    pathReference = sRef(storage, `images/${userId}/${id}/preview`);
    const previewBlob = await getBlob(pathReference);

    // Set the data
    await set(ref(database, "users/" + userId + "/signatureData/" + newSigId), {
      ...responseData,
      id: newSigId,
    });

    const logoRef = sRef(storage, `images/${userId}/${newSigId}/logo`);
    await uploadBytes(logoRef, logoBlob);

    const previewRef = sRef(storage, `images/${userId}/${newSigId}/preview`);
    await uploadBytes(previewRef, previewBlob);

    setSignatures((prevState) => [
      ...prevState,
      { ...responseData, id: newSigId },
    ]);

    setLoader({
      loading: false,
      message: "Loading...",
    });

    // navigate(0);
  };

  const deleteSignatureHandler = async (id) => {
    setLoader({
      loading: true,
      message: "Deleting...",
    });
    const database = getDatabase();
    const storage = getStorage();

    await remove(ref(database, "users/" + userId + "/signatureData/" + id));

    try {
      await deleteObject(sRef(storage, `images/${userId}/${id}/preview`));
    } catch {}

    try {
      await deleteObject(sRef(storage, `images/${userId}/${id}/logo`));
    } catch {}

    const filteredSignatures = signatures.filter(
      (signature) => signature.id !== id
    );

    setSignatures(filteredSignatures);
    setLoader({
      loading: false,
      message: "Loading...",
    });
  };

  const resetSignatureHandler = () => {
    dispatch(resetSignatureData());
    navigate("/signature");
  };

  useEffect(() => {
    const getData = async () => {
      setLoader({ loading: true, message: "Getting Signatures..." });
      const dbRef = ref(getDatabase());
      const response = await get(child(dbRef, `users/${userId}/signatureData`));
      const responseData = response.val();
      if (responseData) setSignatures(Object.values(responseData));
      setLoader({ loading: false, message: "Loading" });
    };

    getData();
  }, []);

  return (
    <section className={classes.dashboard}>
      {loader.loading && (
        <div className={classes.loading}>
          <h1>{loader.message}</h1>
          <CircularProgress size="5rem" />
          {/* <LinearProgress /> */}
        </div>
      )}
      {!loader.loading && (
        <div>
          <h1>Signatures</h1>
          <div className={classes.links}>
            <div>
              {/* <Link style={{ textDecoration: "none" }} to="/signature"> */}
              <Fab
                width="100%"
                variant="extended"
                size="medium"
                color="primary"
                aria-label="add"
                onClick={resetSignatureHandler}
              >
                New Signature
              </Fab>
              {/* </Link> */}
              <Link
                id={classes["last-link"]}
                style={{ textDecoration: "none" }}
                to="/bulksignature"
              >
                <Fab
                  variant="extended"
                  size="medium"
                  color="primary"
                  aria-label="add"
                  onClick={resetSignatureHandler}
                >
                  Bulk Signature
                </Fab>
              </Link>
            </div>
            {/* <Paper
              component="form"
              sx={{
                p: "2px 4px",
                display: "flex",
                alignItems: "center",
                backgroundColor: "#F7F9FC",
                width: 400,
                maxWidth: "90%",
              }}
            >
              <InputBase
                sx={{ ml: 1, flex: 1 }}
                inputProps={{ "aria-label": "search google maps" }}
              />
              <IconButton
                type="button"
                sx={{
                  p: "10px",
                  backgroundColor: "#E6EDF5 !important",
                  borderRadius: "30px",
                }}
                aria-label="search"
              >
                <SearchIcon />
              </IconButton>
            </Paper> */}
          </div>
          <div className={classes.signatures}>
            {signatures.map((signature) => (
              <Signature
                key={signature.id}
                id={signature.id}
                image={signature.imageData}
                duplicateSignatureHandler={duplicateSignatureHandler}
                deleteSignatureHandler={deleteSignatureHandler}
              />
            ))}

            <div>
              <CreateIcon fontSize="large" />
              <p>Need more signatures? Create one right now!</p>
              {/* <Link style={{ textDecoration: "none" }} to="/signature"> */}
              <Fab
                variant="extended"
                size="medium"
                color="primary"
                aria-label="add"
                onClick={resetSignatureHandler}
                // onClick={resetSignatureHandler}
              >
                New Signature
              </Fab>
              {/* </Link> */}
            </div>
          </div>
        </div>
      )}
    </section>
  );
};

export default Dashboard;
