import { imageSliceActions } from "./image-slice";
import { getDownloadURL, getStorage, ref as sRef } from "firebase/storage";

export const setImageBySid = (uid, sid) => {
  return async (dispatch) => {
    try {
      dispatch(imageSliceActions.setLoading({ loading: true }));

      const storage = getStorage();
      const pathReference = sRef(storage, `images/${uid}/${sid}/logo`);
      const url = await getDownloadURL(pathReference);
      dispatch(imageSliceActions.setImageURL({ url }));

      dispatch(imageSliceActions.setLoading({ loading: false }));
    } catch {
      dispatch(imageSliceActions.setLoading({ loading: false }));
      // console.log("No Logo Image");
    }
  };
};
