import React, { useState } from "react";
import { generalSliceActions } from "../../../../store/general-slice";
import { socialSliceActions } from "../../../../store/social-slice";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getDatabase, ref, set } from "firebase/database";
import { getStorage, uploadBytes } from "firebase/storage";
import { ref as sRef } from "firebase/storage";
import CSVReader from "react-csv-reader";
import imageCompression from "browser-image-compression";
import { v4 as uuid } from "uuid";
import { Button, Fab, Typography } from "@mui/material";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import CancelIcon from "@material-ui/icons/Cancel";

import classes from "./UploadCSV.module.css";

const UploadCSV = ({ bulkData, setBulkData, setDisplayFields }) => {
  const [loading, setLoading] = useState();
  const auth = useSelector((state) => state.auth);
  const images = useSelector((state) => state.image);
  const designData = useSelector((state) => state.design);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const database = getDatabase();
  const storage = getStorage();
  const setDynamicData = (signature) => {
    const displayFields = [];
    signature?.phone && displayFields.push("phone");
    signature?.website && displayFields.push("website");
    signature?.email && displayFields.push("email");
    signature?.mobile && displayFields.push("mobile");
    signature?.address && displayFields.push("address");
    signature?.skype && displayFields.push("skype");

    const contactData = {
      phone: "",
      website: "",
      email: "",
      phone1: "",
      website1: "",
      email1: "",
      mobile1: "",
      address1: "",
      skype1: "",
    };

    for (const element of displayFields) {
      contactData[element] = signature[element];
      contactData[`${element}1`] = signature[`${element}1`];
    }

    return {
      generalData: {
        name: signature?.name,
        company: signature?.company,
        position: signature?.position,
        department: signature?.department,
      },
      socialData: {
        facebook: signature?.facebook,
        twitter: signature?.twitter,
        linkedIn: signature?.linkedIn,
        github: signature?.github,
        messenger: signature?.messenger,
        whatsapp: signature?.whatsapp,
      },
      contactData,
      displayFields,
    };
  };

  const resetStateHandler = () => {
    setBulkData(null);
    setDisplayFields(["phone", "website", "email"]);
    dispatch(generalSliceActions.resetState());
    dispatch(socialSliceActions.resetState());
  };

  const onBulkDataLoadHandler = (data, details) => {
    setBulkData({ signatures: data, details });
    if (data?.length > 0) {
      const signature = data[0];
      if (!signature?.name?.trim()) {
        return;
      }

      const { generalData, socialData, contactData, displayFields } =
        setDynamicData(signature);

      dispatch(generalSliceActions.setAllData({ generalData, contactData }));
      dispatch(socialSliceActions.setAllData(socialData));
      setDisplayFields(displayFields);
    }
  };

  const dataUploadHandler = async (signature, compressedImageFile) => {
    if (!signature?.name?.trim()) {
      return;
    }

    const sigId = uuid();
    const data = setDynamicData(signature);
    data.designData = designData;
    data.imageData = { logoWidth: images.logoWidth };

    await set(ref(database, "users/" + auth.id + "/signatureData/" + sigId), {
      id: sigId,
      ...data,
    });

    if (compressedImageFile) {
      const logoRef = sRef(storage, `images/${auth.id}/${sigId}/logo`);
      await uploadBytes(logoRef, compressedImageFile);
    }

    // ADDING PREVIEW IMAGE
    // const previewRef = sRef(storage, `images/${auth.id}/${sigId}/preview`);
    // const preview = await toBlob(<p>{signature.name}</p>, {
    //   backgroundColor: "white",
    //   imagePlaceholder: "123",
    // });
    // await uploadBytes(previewRef, preview);

    // ADDING PREVIEW IMAGE
  };

  const createBulkSignatureHandler = async () => {
    if (bulkData?.signatures?.length > 0) {
      setLoading(true);
      if (images.image) {
        const imageFile = images.image;
        const options = {
          maxSizeMB: 0.5,
          maxWidthOrHeight: 640,
          useWebWorker: true,
        };

        var compressedImageFile = await imageCompression(imageFile, options);
      }

      await Promise.all(
        bulkData.signatures.map(async (signature) => {
          await dataUploadHandler(signature, compressedImageFile);
        })
      );

      navigate("/dashboard");
      setLoading(false);
    } else {
      console.log("No CSV Found.");
    }
  };

  return (
    <div className={classes.main}>
      <Typography color="#2C4166" paragraph fontSize={18} gutterBottom>
        Layout
      </Typography>

      <div className={classes["upload-csv"]}>
        <Button
          variant="contained"
          component="label"
          className={classes.upload}
          sx={{
            height: "8rem",
            width: "8rem",
            borderRadius: "15px",
            padding: 0,

            backgroundColor: "white",
            color: "#2C4166",
            border: "2px solid #E6EDF5",
            textTransform: "capitalize",
          }}
        >
          {bulkData ? (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <CancelIcon fontSize="large" />
              <p
                className={classes["button-text"]}
                style={{ color: "#2c4166", marginBottom: 0 }}
              >
                Remove CSV
              </p>
              <input onClick={resetStateHandler} type="text" hidden />
            </div>
          ) : (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <CloudUploadIcon fontSize="large" />
              <p
                className={classes["button-text"]}
                style={{ color: "#2c4166", marginBottom: 0 }}
              >
                Choose CSV
              </p>
              <CSVReader
                onFileLoaded={onBulkDataLoadHandler}
                inputStyle={{ display: "none" }}
                parserOptions={{
                  header: true,
                  dynamicTyping: true,
                  skipEmptyLines: true,
                  // transformHeader: (header) =>
                  //   header.toLowerCase().replace(/\W/g, "_"),
                }}
              />
            </div>
          )}
        </Button>

        <div className={classes.details}>
          <p>
            <span>File name:</span> {bulkData?.details.name || "-"}
          </p>
          <p>
            <span>No. of signatures:</span> {bulkData?.signatures.length || "-"}
          </p>
        </div>
      </div>

      <Fab
        variant="extended"
        color="primary"
        aria-label="add"
        href="https://firebasestorage.googleapis.com/v0/b/easy-signature-40115.appspot.com/o/misc%2FEasySignatureBulkSyntax.csv?alt=media&token=0da37a10-b6a3-4a24-a3f5-2a3e68e51c26"
        target="_blank"
        sx={{
          backgroundColor: "#304aff",
          textTransform: "capitalize",
          height: "40px",
          margin: "0 1rem 0rem 0",
        }}
        disabled={loading}
      >
        Download Reference CSV
      </Fab>
      <Fab
        variant="extended"
        color="primary"
        aria-label="add"
        type="submit"
        onClick={createBulkSignatureHandler}
        sx={{
          backgroundColor: "#304aff",
          textTransform: "capitalize",
          height: "40px",
          margin: "0 1rem 0rem 0",
        }}
        disabled={loading}
      >
        {loading ? "Creating..." : "Create Bulk Signatures"}
      </Fab>
    </div>
  );
};

export default UploadCSV;
