import React from "react";
import { Fragment } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";

import classes from "./Footer.module.css";

const Footer = ({ benefitsRef, featuresRef }) => {
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
  const location = useLocation();
  const navigate = useNavigate();

  const scrollTopViewHandler = (event) => {
    event.preventDefault();
    navigate(`/${event.target.id}`);
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const scrollFeaturesIntoViewHandler = (event) => {
    event.preventDefault();
    if (location.pathname === "/" && window.innerWidth > 426) {
      featuresRef.current.scrollIntoView();
    } else {
      navigate("/");
      setTimeout(() => {
        featuresRef.current.scrollIntoView();
      }, 200);
    }
    // await setTimeout(null, 1500);
    // featuresRef.current.scrollIntoView();
  };

  const scrollBenefitsIntoViewHandler = (event) => {
    event.preventDefault();
    if (location.pathname === "/" && window.innerWidth > 426) {
      benefitsRef.current.scrollIntoView();
    } else {
      navigate("/");
      setTimeout(() => {
        benefitsRef.current.scrollIntoView();
      }, 200);
    }
    // await setTimeout(null, 1500);
    // benefitsRef.current.scrollIntoView();
  };

  return (
    <div
      style={{ backgroundColor: location.pathname === "/" ? "white" : "" }}
      className={classes.background}
    >
      <div className={classes.footer}>
        <div className={classes["footer-grid"]}>
          <div style={{ marginRight: "40px" }}>
            <img src="../../../images/logo.png" alt="Easy Signature Logo" />
            <div className={classes.text}>
              EasySignature.Email is a free email signature generator that makes
              it easy to create and customise professional email signatures for
              your business correspondence. Using EasySignature.Email, you can
              add a personal touch to each of your emails and promote your
              products or services with ease. Additionally, EasySignature.Email
              is a great way to build trust with potential customers and
              reinforce your company's branding.
            </div>
          </div>

          <div className={classes["footer-links"]}>
            <div
              style={{ fontSize: "24px" }}
              className={classes["small-heading"]}
            >
              Menu
            </div>
            <br />
            <ul>
              <li id="" onClick={scrollTopViewHandler} className={classes.text}>
                Home
              </li>
              <a onClick={scrollFeaturesIntoViewHandler}>
                <li className={classes.text}>Features</li>
              </a>
              <a onClick={scrollBenefitsIntoViewHandler}>
                <li className={classes.text}>Benefits</li>
              </a>
            </ul>
          </div>

          <div className={classes["footer-links"]}>
            <div
              style={{ fontSize: "24px" }}
              className={classes["small-heading"]}
            >
              Links
            </div>
            <br />
            <ul>
              <li
                id="privacy-policy"
                onClick={scrollTopViewHandler}
                className={classes.text}
              >
                Privacy policy
              </li>
              <li
                id="terms-and-services"
                onClick={scrollTopViewHandler}
                className={classes.text}
              >
                Terms and Services
              </li>
            </ul>
          </div>

          {isLoggedIn ? (
            <div className={classes["footer-buttons"]}>
              <div>
                <h2
                  style={{ fontSize: "24px" }}
                  className={classes["small-heading"]}
                >
                  Contact Us
                </h2>
                <p
                  style={{ color: "black", fontWeight: "bold" }}
                  className={classes.text}
                >
                  Email:
                </p>
                <p className={classes.text}>info@easysignature.email</p>
              </div>
              <div
                id="signature"
                onClick={scrollTopViewHandler}
                className={classes["create-sig-button"]}
                style={{ cursor: "pointer", marginTop: "2rem" }}
              >
                Create Signature
              </div>
            </div>
          ) : (
            <div className={classes["footer-buttons"]}>
              <div>
                <h2
                  style={{ fontSize: "24px" }}
                  className={classes["small-heading"]}
                >
                  Contact Us
                </h2>
                <p
                  style={{ color: "black", fontWeight: "bold" }}
                  className={classes.text}
                >
                  Email:
                </p>
                <p className={classes.text}>info@easysignature.email</p>
              </div>
              <div
                id="login"
                onClick={scrollTopViewHandler}
                className={classes["create-sig-button-inverse"]}
                style={{ cursor: "pointer" }}
              >
                Sign In
              </div>
              <div
                id="signup"
                onClick={scrollTopViewHandler}
                className={classes["create-sig-button"]}
                style={{ cursor: "pointer" }}
              >
                Sign Up
              </div>
            </div>
          )}

          {!isLoggedIn && (
            <Fragment>
              <div
                id="login"
                onClick={scrollTopViewHandler}
                className={`${classes["create-sig-button-inverse"]} ${classes["mobile-footer-button"]}`}
                style={{ cursor: "pointer" }}
              >
                Sign In
              </div>
              <div
                id="signup"
                onClick={scrollTopViewHandler}
                className={`${classes["create-sig-button"]} ${classes["mobile-footer-button"]}`}
                style={{ cursor: "pointer" }}
              >
                Sign Up
              </div>
            </Fragment>
          )}
        </div>
        <div className={classes.hr}>
          <hr />
        </div>
        <br />
        <div className={classes.copyright}>
          &copy; 2022 EasySignature. All rights reserved.
        </div>
      </div>
    </div>
  );
};

export default Footer;
