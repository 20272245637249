import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  facebook: "https://fb.me/",
  twitter: "https://twitter.com/",
  linkedIn: "http://www.linkedin.com/in/",
  github: "https://github.com/",
  messenger: "https://m.me/",
  whatsapp: "https://wa.me/",
};

const socialSlice = createSlice({
  name: "social",
  initialState,
  reducers: {
    setSocialHandles(state, action) {
      state[action.payload.name] = action.payload.value;
    },
    setAllData(state, action) {
      state.facebook = action.payload.facebook;
      state.twitter = action.payload.twitter;
      state.linkedIn = action.payload.linkedIn;
      state.github = action.payload.github;
      state.messenger = action.payload.messenger;
      state.whatsapp = action.payload.whatsapp;
      // state.slack = action.payload.slack;
      // console.log(action.payload.social);
    },
    resetState() {
      return initialState;
    },
  },
});

export const socialSliceActions = socialSlice.actions;
export default socialSlice.reducer;
