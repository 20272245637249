import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  fontFamily: "Segoe UI",
  fontSize: "14",
  tempColor: "#00cfd6",
  textColor: "#000000",
  layout: 1,
};

const designSlice = createSlice({
  name: "design",
  initialState,
  reducers: {
    setFontFamily(state, action) {
      state.fontFamily = action.payload.fontFamily;
    },

    setFontSize(state, action) {
      state.fontSize = action.payload.fontSize;
    },
    setTempColor(state, action) {
      state.tempColor = action.payload.tempColor;
    },
    setTextColor(state, action) {
      state.textColor = action.payload.textColor;
    },
    setLayout(state, action) {
      state.layout = action.payload.layout;
    },
    setAllData(state, action) {
      state.fontFamily = action.payload.fontFamily;
      state.fontSize = action.payload.fontSize;
      state.tempColor = action.payload.tempColor;
      state.textColor = action.payload.textColor;
      state.layout = action.payload.layout;
    },
    resetState() {
      return initialState;
    },
  },
});

export const designSliceActions = designSlice.actions;
export default designSlice.reducer;
