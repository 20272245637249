import React, { useState } from "react";
import {
  addUserHandler,
  addUserWithFacebookHandler,
  addUserWithGoogleHandler,
  loginUserHandler,
  passwordResetHandler,
} from "../../store/auth-actions";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import GoogleButton from "react-google-button";
import PhoneInput from "react-phone-number-input";
import { Alert, Button, Fab, Skeleton, TextField } from "@mui/material";
import SendIcon from "@material-ui/icons/Send";
import { faGoogle } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import classes from "./Auth.module.css";
import "react-phone-number-input/style.css";

// LOADER, ERROR HANDLING

function Auth({ isLogin = true }) {
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const auth = useSelector((state) => state.auth);

  const [loading, setLoading] = useState(false);
  const [login, setLogin] = useState(isLogin);
  // console.log(props.isLogin);
  const [number, setNumber] = useState("");
  const [userCredentials, setUserCredentials] = useState({
    name: "",
    email: "",
    password: "",
    // number: "",
  });

  const userCredentialsHandler = (event) => {
    setUserCredentials((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value,
    }));
  };

  const signUpHandler = async (event) => {
    event.preventDefault();
    setLoading(true);

    // const { name, email, password, number } = userCredentials;
    const { name, email, password } = userCredentials;

    const response = await dispatch(
      addUserHandler(name, email, password, number)
    );

    setLoading(false);
    if (response) {
      navigate("/dashboard");
    }
  };

  const signUpWithGoogleHandler = async (event) => {
    event.preventDefault();
    setLoading(true);

    const response = await dispatch(addUserWithGoogleHandler());
    setLoading(false);

    if (response) {
      navigate("/dashboard");
    }
  };

  const signUpWithFacebookHandler = async (event) => {
    event.preventDefault();
    setLoading(true);

    const response = await dispatch(addUserWithFacebookHandler());
    setLoading(false);

    if (response) {
      navigate("/dashboard");
    }
  };

  const signInHandler = async (event) => {
    event.preventDefault();
    setLoading(true);
    const { email, password } = userCredentials;
    const response = await dispatch(loginUserHandler(email, password));

    setLoading(false);
    if (response) {
      navigate("/dashboard");
    }
  };

  const forgetPasswordHandler = async (event) => {
    event.preventDefault();
    setLoading(true);
    const { email } = userCredentials;
    await dispatch(passwordResetHandler(email));
    setLoading(false);
  };

  return (
    <div className={classes["auth"]}>
      <form onSubmit={login ? signInHandler : signUpHandler}>
        <h1>{login ? "Log In" : "Create an account"}</h1>
        {!login &&
          (loading ? (
            <Skeleton width="100%" height="50px" />
          ) : (
            <TextField
              required
              id="Name"
              label="Name"
              name="name"
              variant="standard"
              value={userCredentials.name}
              onChange={userCredentialsHandler}
            />
          ))}
        {loading ? (
          <Skeleton sx={{ mt: "2rem" }} width="100%" height="50px" />
        ) : (
          <TextField
            required
            id="Email"
            name="email"
            label="Email"
            variant="standard"
            value={userCredentials.email}
            onChange={userCredentialsHandler}
          />
        )}

        {loading ? (
          <Skeleton sx={{ mt: "2rem" }} width="100%" height="50px" />
        ) : (
          <TextField
            required
            id="Password"
            name="password"
            label="Password"
            variant="standard"
            type="password"
            value={userCredentials.password}
            onChange={userCredentialsHandler}
          />
        )}
        {!login &&
          (loading ? (
            <Skeleton sx={{ mt: "2rem" }} width="100%" height="50px" />
          ) : (
            // <TextField
            //   required
            //   id="Phone number"
            //   label="Phone number"
            //   name="number"
            //   variant="standard"
            //   value={userCredentials.number}
            //   onChange={userCredentialsHandler}
            // />
            <PhoneInput
              international
              countryCallingCodeEditable={false}
              defaultCountry="PK"
              placeholder="Enter phone number"
              value={number}
              onChange={setNumber}
              className={classes.phoneNum}
              // country="PK"
            />
          ))}
        {auth.error &&
          (loading ? (
            <Skeleton sx={{ mt: "2rem" }} width="100%" height="50px" />
          ) : (
            <Alert className={classes.error} severity="error">
              {auth.error}
            </Alert>
          ))}
        {auth.message &&
          (loading ? (
            <Skeleton sx={{ mt: "2rem" }} width="100%" height="50px" />
          ) : (
            <Alert className={classes.error} severity="info">
              {auth.message}
            </Alert>
          ))}
        {auth.error.length > 0 &&
          auth.error !== "Email does not exist." &&
          login && (
            <Button
              variant="text"
              type="submit"
              onClick={forgetPasswordHandler}
              sx={{
                marginBottom: 0,
                backgroundColor: "white !important",
                padding: 0,
              }}
            >
              Forgot Password?
            </Button>
          )}
        {login ? (
          <Fab
            className={classes.main}
            variant="extended"
            color="primary"
            aria-label="add"
            type="submit"
            disabled={loading}
          >
            <SendIcon />
            <span className={classes.text}>Sign In</span>
          </Fab>
        ) : (
          <Fab
            className={classes.main}
            variant="extended"
            color="primary"
            aria-label="add"
            type="submit"
            disabled={loading}
          >
            <SendIcon />
            <span className={classes.text}>Sign up</span>
          </Fab>
        )}

        {/* <br /> */}
        <GoogleButton
          style={{ marginTop: "2rem" }}
          onClick={signUpWithGoogleHandler}
          disabled={loading}
        />
        {/* <button
          disabled={loading}
          onClick={signUpWithGoogleHandler}
          className={classes["google-auth-button"]}
        >
          <span id={classes["google-logo"]}>
            <img
              src="https://developers.google.com/static/identity/images/g-logo.png"
              alt="Google Logo"
            />
          </span>
          <span id={classes["google-text"]}>Sign in with Google</span>
        </button> */}
        {/* <Button
          variant="contained"
          startIcon={<FontAwesomeIcon icon={faGoogle} />}
          disabled={loading}
          onClick={signUpWithGoogleHandler}
        >
          Sign up with Google
        </Button> */}
        {/* <Button
          variant="contained"
          startIcon={<FontAwesomeIcon icon={faFacebook} />}
          disabled={loading}
          onClick={signUpWithFacebookHandler}
        >
          Sign up with Facebook
        </Button> */}
        <a
          onClick={() => setLogin((prevState) => !prevState)}
          style={{ marginTop: "20px", cursor: "pointer", fontSize: "large" }}
        >
          {login
            ? "Don't have an account? Sign up!"
            : "Have an account? Sign in!"}
        </a>
      </form>
    </div>
  );
}

export default Auth;
