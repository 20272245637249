import React, { Fragment, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { logoutUserHandler } from "../../store/auth-actions";
import {
  Avatar,
  Box,
  Button,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Typography,
  Tooltip,
} from "@mui/material";
import BenefitIcon from "@material-ui/icons/LibraryAddCheck";
import DashboardIcon from "@material-ui/icons/Dashboard";
import FeatureIcon from "@material-ui/icons/FeaturedPlayList";
import HomeIcon from "@material-ui/icons/Home";
import MenuIcon from "@material-ui/icons/Menu";
import AccountIcon from "@material-ui/icons/AccountBox";

import classes from "./Navbar.module.css";

const Navbar = ({ featuresRef, benefitsRef }) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const navigate = useNavigate();

  const [anchorElUser, setAnchorElUser] = useState(null);
  const [drawerState, setDrawerState] = useState(false);

  const scrollFeaturesIntoViewHandler = (event) => {
    event.preventDefault();
    if (location.pathname === "/" && window.innerWidth > 426) {
      featuresRef.current.scrollIntoView();
    } else {
      navigate("/");
      setTimeout(() => {
        featuresRef.current.scrollIntoView();
      }, 200);
    }
    // await setTimeout(null, 1500);
    // featuresRef.current.scrollIntoView();
  };

  const scrollBenefitsIntoViewHandler = (event) => {
    event.preventDefault();
    if (location.pathname === "/" && window.innerWidth > 426) {
      benefitsRef.current.scrollIntoView();
    } else {
      navigate("/");
      setTimeout(() => {
        benefitsRef.current.scrollIntoView();
      }, 200);
    }
    // await setTimeout(null, 1500);
    // benefitsRef.current.scrollIntoView();
  };

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const toggleDrawer = () => setDrawerState((prevState) => !prevState);

  const signOutUserHandler = async () => {
    handleCloseUserMenu();
    const response = await dispatch(logoutUserHandler());
    if (response) {
      navigate("/login");
    }
  };

  const list = () => (
    <Box
      sx={{ width: 250 }}
      role="presentation"
      onClick={toggleDrawer}
      onKeyDown={toggleDrawer}
      id={classes["dropdown-box"]}
    >
      <div className={classes["dropdown-logo"]}>
        <img
          src="../../../images/logo.png"
          alt="Easy Signature"
          title="Easy Signature"
        />
      </div>
      <Divider />
      <List>
        <Link to="/">
          <ListItem disablePadding>
            <ListItemButton>
              <ListItemIcon>
                <HomeIcon />
              </ListItemIcon>
              <ListItemText primary="Home" />
            </ListItemButton>
          </ListItem>
        </Link>
        <Divider />
        <a onClick={scrollFeaturesIntoViewHandler}>
          <ListItem disablePadding>
            <ListItemButton>
              <ListItemIcon>
                <FeatureIcon />
              </ListItemIcon>
              <ListItemText primary="Features" />
            </ListItemButton>
          </ListItem>
        </a>
        <Divider />
        <a onClick={scrollBenefitsIntoViewHandler}>
          <ListItem disablePadding>
            <ListItemButton>
              <ListItemIcon>
                <BenefitIcon />
              </ListItemIcon>
              <ListItemText primary="Benefits" />
            </ListItemButton>
          </ListItem>
        </a>
        {auth.isLoggedIn && (
          <Fragment>
            <Divider />
            <Link to="/dashboard">
              <ListItem disablePadding>
                <ListItemButton>
                  <ListItemIcon>
                    <DashboardIcon />
                  </ListItemIcon>
                  <ListItemText primary="Dashboard" />
                </ListItemButton>
              </ListItem>
            </Link>
            <Divider />
            <Link to="/profile">
              <ListItem disablePadding>
                <ListItemButton>
                  <ListItemIcon>
                    <AccountIcon />
                  </ListItemIcon>
                  <ListItemText primary="Profile" />
                </ListItemButton>
              </ListItem>
            </Link>
          </Fragment>
        )}
        <Divider />
        <div className={classes["dropdown-buttons"]}>
          {!auth.isLoggedIn ? (
            <Fragment>
              <Link to="/login">
                <div className={classes["create-sig-button-inverse"]}>
                  Sign In
                </div>
              </Link>
              <Link to="/signup">
                <div className={classes["create-sig-button"]}>Sign Up</div>
              </Link>
            </Fragment>
          ) : (
            <a onClick={signOutUserHandler}>
              <div className={classes["create-sig-button"]}>Sign Out</div>
            </a>
          )}
        </div>
      </List>
    </Box>
  );

  return (
    <div
      className={classes.background}
      style={{ backgroundColor: location.pathname === "/" ? "white" : "" }}
    >
      <div className={classes.navbar}>
        <div className={classes.dropdown}>
          <Fragment>
            <div className={classes.logo}>
              <img
                src="../../../images/logo.png"
                alt="Easy Signature"
                title="Easy Signature"
              />
            </div>
            <Button onClick={toggleDrawer}>
              <MenuIcon fontSize="large" />
            </Button>
            <Drawer anchor={"right"} open={drawerState} onClose={toggleDrawer}>
              {list()}
            </Drawer>
          </Fragment>
        </div>
        <div className={classes.navrows}>
          <div className={classes.logo}>
            <img
              src="../../../images/logo.png"
              alt="Easy Signature"
              title="Easy Signature"
            />
          </div>
          <Link to="/">
            <div>Home</div>
          </Link>
          <a onClick={scrollFeaturesIntoViewHandler}>
            <div>Features</div>
          </a>
          <a onClick={scrollBenefitsIntoViewHandler}>
            <div>Benefits</div>
          </a>
        </div>

        <div className={classes.navrows}>
          <Link to={auth.isLoggedIn ? "/dashboard" : "/login"}>
            <div>{auth.isLoggedIn ? "Dashboard" : "Sign In"}</div>
          </Link>
          {auth.isLoggedIn ? (
            <Box
              sx={{
                flexGrow: 0,
                marginLeft: "2rem",
              }}
            >
              <Tooltip title="Open settings">
                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                  <Avatar
                    sx={{ backgroundColor: "#304aff" }}
                    alt={auth.name}
                    src="/static/images/avatar/2.jpg"
                  />
                </IconButton>
              </Tooltip>
              <Menu
                sx={{ mt: "45px" }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
              >
                {auth.isLoggedIn && (
                  <Fragment>
                    <Link style={{ color: "black" }} to="/profile">
                      <MenuItem>
                        <Typography textAlign="center">Profile</Typography>
                      </MenuItem>
                    </Link>
                    <MenuItem onClick={signOutUserHandler}>
                      <Typography textAlign="center">Logout</Typography>
                    </MenuItem>
                  </Fragment>
                )}
              </Menu>
            </Box>
          ) : (
            <Link to="/signup">
              <div className={classes["create-sig-button"]}>
                <div>Sign Up</div>
              </div>
            </Link>
          )}
        </div>
      </div>
    </div>
  );
};

export default Navbar;
