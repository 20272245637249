import React, { Fragment, useState, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import imageCompression from "browser-image-compression";
import { toBlob } from "html-to-image";
import { v4 as uuid } from "uuid";
import { getDatabase, ref, set } from "firebase/database";
import { getStorage, uploadBytes } from "firebase/storage";
import { ref as sRef } from "firebase/storage";
import { Fab, LinearProgress } from "@mui/material";
import Text from "./PreviewSections/Text";
import Social from "./PreviewSections/Social";

import classes from "./Preview.module.css";

const Wrapper = ({ children }) => {
  const designData = useSelector((state) => state.design);

  const wrapper =
    designData.layout === 2 ||
    designData.layout === 3 ||
    designData.layout === 4 ||
    designData.layout === 5 ||
    designData.layout === 11 ||
    designData.layout === 12 ? (
      <td
        valign="top"
        style={{
          borderLeft: `2px solid ${designData.tempColor}`,
          paddingLeft: "9px",
        }}
      >
        <table style={{ borderCollapse: "collapse" }}>{children}</table>
      </td>
    ) : designData.layout === 6 ||
      designData.layout === 7 ||
      designData.layout === 8 ||
      designData.layout === 9 ||
      designData.layout === 10 ? (
      <td valign="top">
        <table style={{ borderCollapse: "collapse" }}>{children}</table>
      </td>
    ) : (
      <Fragment>{children}</Fragment>
    );

  return wrapper;
};

const Preview = (props) => {
  const [loading, setLoading] = useState(false);
  let navigate = useNavigate();
  const sId = useParams()?.sid;
  const contentRef = useRef();

  const auth = useSelector((state) => state.auth);
  const generalData = useSelector((state) => state.general.generalData);
  const contactData = useSelector((state) => state.general.contactData);
  const socialHandles = useSelector((state) => state.social);
  const images = useSelector((state) => state.image);
  const designData = useSelector((state) => state.design);

  const copyDataHandler = () => {
    var range = document.createRange();
    range.selectNode(document.querySelector(".copy-content"));
    window.getSelection().removeAllRanges(); // clear current selection
    window.getSelection().addRange(range); // to select text
    document.execCommand("copy");
    window.getSelection().removeAllRanges(); // to deselect
  };

  const dataUploadHandler = async () => {
    // dispatch(imageSliceActions.setLoading({ loading: true }));
    setLoading(true);

    const sigId = sId ? sId : uuid();
    const database = getDatabase();
    const storage = getStorage();

    // const firestore = getFirestore();
    // const generalData = useSelector((state) => state.general.generalData);
    // const contactData = useSelector((state) => state.general.contactData);

    // console.log(props.displayFields);

    const data = {
      generalData,
      contactData,
      imageData: {
        logoWidth: images.logoWidth,
      },
      socialData: socialHandles,
      designData,
      displayFields: props.displayFields,
    };

    await set(ref(database, "users/" + auth.id + "/signatureData/" + sigId), {
      id: sigId,
      ...data,
    });

    // console.log("Data Uploaded!");

    if (images.image) {
      // IMAGE COMPRESSION ADDED
      const imageFile = images.image;
      console.log(`originalFile size ${imageFile.size / 1024 / 1024} MB`);

      const options = {
        maxSizeMB: 0.5,
        maxWidthOrHeight: 640,
        useWebWorker: true,
      };

      // try{
      const compressedImageFile = await imageCompression(imageFile, options);
      console.log(
        `compressedFile size ${compressedImageFile.size / 1024 / 1024} MB`
      );
      // }catch{console.log("Compression was not possible!");}

      const logoRef = sRef(storage, `images/${auth.id}/${sigId}/logo`);

      // await uploadBytes(logoRef, images.image);
      await uploadBytes(logoRef, compressedImageFile);

      // console.log(imageResponse);

      // console.log("Image Uploaded!");
    }
    // ADDING PREVIEW IMAGE

    const previewRef = sRef(storage, `images/${auth.id}/${sigId}/preview`);

    // dispatch(imageSliceActions.setLoading({ loading: false }));
    const preview = await toBlob(contentRef.current, {
      backgroundColor: "white",
      imagePlaceholder: "123",
    });

    await uploadBytes(previewRef, preview);

    // console.log("Preview Uploaded!");

    setLoading(false);
    // navigate("/dashboard");
    navigate(`/preview/${sigId}`);
  };

  // const checkSocialInputs = [
  //   socialHandles.facebook && socialHandles.facebook !== "https://fb.me/",
  //   socialHandles.twitter && socialHandles.twitter !== "https://twitter.com/",
  //   socialHandles.linkedIn &&
  //     socialHandles.linkedIn !== "http://www.linkedin.com/in/",
  //   socialHandles.github && socialHandles.github !== "https://github.com/",
  //   socialHandles.messenger && socialHandles.messenger !== "https://m.me/",
  //   socialHandles.whatsapp && socialHandles.whatsapp !== "https://wa.me/",
  // ];

  // Checks if any true value in socialInputs
  // const showSocialHandles = checkSocialInputs.find((element) => element);

  // const showSocialHandles =
  //   checkSocialInputs[0] ||
  //   checkSocialInputs[1] ||
  //   checkSocialInputs[2] ||
  //   checkSocialInputs[3] ||
  //   checkSocialInputs[4] ||
  //   checkSocialInputs[5];

  // useEffect(() => {
  //   console.log(
  //     renderToString(
  //       <Provider store={store}>
  //         <PreviewStatic />
  //       </Provider>
  //     )
  //   );
  // }, [images]);

  return (
    <div className={classes.card}>
      <div className={classes.header}>
        <h4>Signature Preview</h4>
      </div>
      <div className={classes.content}>
        <div className="copy-content">
          <table
            width={designData.layout !== 8 ? "700" : "640"}
            cellPadding="0"
            ref={contentRef}
            className={classes.main}
            // fontSize={`${designData.fontSize}px`}
            style={{
              // fontSize: `${designData.fontSize}px`,
              // fontFamily: designData.fontFamily,
              // maxWidth: "600px",
              color: designData.textColor,
              background: "none",
            }}
          >
            <tr>
              <td>
                <table cellPadding="0">
                  <tr>
                    <td
                      valign="top"
                      style={{
                        margin: "0.1px",
                        paddingRight: "9px",
                        // borderRadius: "20px",
                        // border: "solid 1px #000",
                      }}
                    >
                      {images.imageURL && (
                        <img
                          src={
                            images.loading
                              ? "../../../../images/loading.jpg"
                              : images.imageURL
                          }
                          width={images.logoWidth}
                          crossOrigin="anonymous"
                          alt="Email Signature by EasySignature.Email"
                          title="Email Signature by EasySignature.Email"
                        />
                      )}
                    </td>

                    <Wrapper>
                      {images.loading ? (
                        <div className={classes.loading}>
                          <LinearProgress />
                          <br />
                          <LinearProgress />
                          <br />
                          <LinearProgress />
                        </div>
                      ) : (
                        <Text filteredContactData={props.displayFields} />
                      )}
                      {designData.layout !== 8 && <Social />}
                    </Wrapper>
                  </tr>
                </table>
              </td>
            </tr>
            {designData.layout === 8 && (
              <tr>
                <td>
                  <table width="100%" style={{ width: "100%" }}>
                    {/* <tr> */}
                    <Social />
                    {/* </tr> */}
                  </table>
                </td>
              </tr>
            )}
            {/* <hr id={classes["branding-seperator"]} />
          <img
            width="200"
            src="https://firebasestorage.googleapis.com/v0/b/easy-signature-40115.appspot.com/o/misc%2Fimage%20(13).png?alt=media&token=7fba6f7e-6729-4dcf-9137-7c34e00fcba9"
            alt="Made using easy signature"
          /> */}
          </table>
          <table
            width="900"
            className="branding-table"
            style={{ background: "none" }}
          >
            <tr>
              <td style={{ padding: "5px 0px 0px 0px" }}></td>
            </tr>
            <tr>
              <td
                style={{
                  borderTop: "2px solid rgb(238, 238, 238)",
                  paddingTop: "5px",
                  fontSize: "10px",
                }}
              >
                <a href="https://easysignature.email/" target="_blank">
                  <img
                    width="150"
                    // src="https://firebasestorage.googleapis.com/v0/b/easy-signature-40115.appspot.com/o/misc%2Fimage%20(13).png?alt=media&token=7fba6f7e-6729-4dcf-9137-7c34e00fcba9"
                    src="../../../../../images/logo_preview.png"
                    alt="Made Using EmailSignature.Email"
                    title="Made Using EmailSignature.Email"
                  />
                </a>
              </td>
            </tr>
          </table>
        </div>
      </div>

      <div className={classes.footer}>
        {!props.bulkCreation && (
          <Fab
            variant="extended"
            color="primary"
            aria-label="add"
            type="submit"
            onClick={props.guide ? copyDataHandler : dataUploadHandler}
            sx={{
              backgroundColor: "#304aff",
              textTransform: "capitalize",
              height: "40px",
              margin: "0 1rem 1rem 0",
            }}
            disabled={loading}
          >
            {loading
              ? "Saving..."
              : props.guide
              ? "Copy to clipboard"
              : "Save Signature"}
          </Fab>
        )}
      </div>
    </div>
  );
};

export default Preview;
