import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  id: "",
  name: "",
  email: "",
  number: "",
  isLoggedIn: false,
  error: "",
  message: "",
  // token: "",
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setName(state, action) {
      state.name = action.payload.name;
    },
    setNumber(state, action) {
      state.number = action.payload.number;
    },
    setError(state, action) {
      state.error = action.payload.error;
      state.message = "";
      // console.log(action.payload.error);
    },
    setMessage(state, action) {
      state.message = action.payload.message;
      state.error = "";
    },
    signInUser(state, action) {
      state.id = action.payload.id;
      state.name = action.payload.name;
      state.email = action.payload.email;
      state.number = action.payload.number;
      state.isLoggedIn = true;
      state.error = "";
    },
    signOutUser() {
      return initialState;
    },
  },
});

export const authSliceActions = authSlice.actions;
export default authSlice.reducer;
