import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  image: null,
  imageURL: "",
  logoWidth: 100,
  loading: false,
};

const imageSlice = createSlice({
  name: "image",
  initialState,
  reducers: {
    setImageURL(state, action) {
      state.image = action.payload.image;
      state.imageURL = action.payload.url;
      // console.log(state.image);
    },
    setLogoWidth(state, action) {
      state.logoWidth = action.payload.width;
    },
    setLoading(state, action) {
      state.loading = action.payload.loading;
    },
    resetState() {
      return initialState;
    },
  },
});

export const imageSliceActions = imageSlice.actions;
export default imageSlice.reducer;
