import { initializeApp } from "firebase/app";
import { getDatabase } from "firebase/database";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyDKJJtA9jvQAMZovVWDP_Y58gGoxzrehHI",
  // authDomain: "easy-signature-40115.firebaseapp.com",
  authDomain: "easysignature.email",
  projectId: "easy-signature-40115",
  storageBucket: "easy-signature-40115.appspot.com",
  messagingSenderId: "145502082800",
  appId: "1:145502082800:web:6a7ab6037a6905e30cd435",
  measurementId: "G-M1GGSTHF0Y",
  databaseURL: "https://easy-signature-40115-default-rtdb.firebaseio.com/",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const database = getDatabase(app);
const db = getFirestore(app);
const storage = getStorage(app);
const auth = getAuth(app);
