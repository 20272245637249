import classes from "./Guide.module.css";

export const text = {
  outlook: (
    <article>
      <ol>
        <li>
          Generate an email signature using <strong>Easy Signature</strong>{" "}
          editor.
        </li>
        <li>
          Click on <strong>Copy to clipboard</strong> button.
        </li>
        <li>
          Go to your <strong>Outlook</strong> and compose a{" "}
          <strong>New Email</strong>.
        </li>
        <li>
          Go to <strong>Message Tab</strong> and click on{" "}
          <strong>Signature &gt; Signatures</strong>
        </li>
        <li>
          Click <strong>New</strong> and give name to your signature.
        </li>
        <li>
          Click the <strong>+</strong> icon to add a new signature.
        </li>
      </ol>
      <p>
        <strong>Mac:</strong> Paste your signature into the{" "}
        <strong>Signature box (Ctrl+V or Command+V)</strong>, click on the
        clipboard that popped-up and select{" "}
        <strong>Keep Source Formatting</strong> pasting option and press{" "}
        <strong>OK</strong>.
      </p>
      <p>
        <strong>Windows:</strong> Right click the{" "}
        <strong>Edit Signature box</strong> and choose{" "}
        <strong>Keep Source Formatting</strong> pasting option and press{" "}
        <strong>OK</strong>.
      </p>
    </article>
  ),
  applemail: (
    <article>
      <ol>
        <li>
          Generate an email signature using <strong>Easy Signature</strong>{" "}
          editor.
        </li>
        <li>
          Click on <strong>Copy to clipboard</strong> button.
        </li>
        <li>
          In Apple Mail Click on <strong>Preferences</strong> mac-command (or{" "}
          <strong>Choose Mail &gt; Preferences &gt; Signatures</strong>).
        </li>
        <li>
          Choose <strong>Google</strong> to add the signature to all accounts OR
          choose a specific account.
        </li>
        <li>
          Click the <strong>+</strong> icon to add a new signature and give it a
          name.
        </li>
        <li>
          Uncheck the box{" "}
          <strong>“Always match my default message font”</strong>.
        </li>
        <li>
          Paste your signature into the{" "}
          <strong>Edit Signature box (Ctrl+V or Command+V)</strong>.
        </li>
      </ol>
      <p>
        <strong>Important:</strong>
        <br />
        When you Paste your signature, it may look like a blank image with wrong
        text. It's OK. Compose a new email to see your new signature.{" "}
      </p>
      <p>
        Use Chrome or Mozilla browser when you click{" "}
        <strong>"Copy to clipboard"</strong> button to keep right formatting.
      </p>
    </article>
  ),
  yahoo: (
    <article>
      <ol>
        <li>
          Generate an email signature using <strong>Easy Signature</strong>{" "}
          editor.
        </li>
        <li>
          Click on <strong>Copy to clipboard</strong> button.
        </li>
        <li>
          Open{" "}
          <a target="blank" href="https://mail.yahoo.com/" rel="nofollow">
            Yahoo
          </a>
          .
        </li>
        <li>
          In the top right, click{" "}
          <strong>Settings &gt; More Settings &gt; Writing emails</strong>.
        </li>
        <li>
          Click checkbox button opposite your email and paste the signature{" "}
          <strong>(Ctrl+V or Command+V)</strong> into text area.
        </li>
      </ol>
      <p>
        <strong>Important:</strong>
        <br />
        Use Chrome or Mozilla browser when you click{" "}
        <strong>"Copy to clipboard"</strong> button to keep right formatting.
      </p>
    </article>
  ),
  thunderbird: (
    // <article>
    //   <ol>
    //     <li>
    //       Generate an email signature using <strong>Easy Signature</strong>{" "}
    //       editor.
    //     </li>
    //     <li>
    //       Copy HTML code from <strong>"Source code"</strong> section.
    //     </li>
    //     <li>
    //       Within Thunderbird click <strong>Tools &gt; Account Settings</strong>,
    //       and then, in the left panel, select the account for which you want to
    //       create a signature.
    //     </li>
    //     <li>
    //       Check the "Use HTML" option and <strong>Paste</strong> your signature
    //       (Ctrl+V or Command+V).
    //     </li>
    //   </ol>
    // </article>
    <article>
      <ol>
        <li>
          Generate an email signature using <strong>Easy Signature</strong>{" "}
          editor.
        </li>
        <li>
          Click on <strong>Copy to clipboard</strong> button.
        </li>
        <li>
          Go to your <strong>Thunderbird</strong> and compose a{" "}
          <strong>New Email</strong>.
        </li>
        {/* <li>
          Go to <strong>Message Tab</strong> and click on{" "}
          <strong>Signature &gt; Signatures</strong>
        </li>
        <li>
          Click <strong>New</strong> and give name to your signature.
        </li>
        <li>
          Click the <strong>+</strong> icon to add a new signature.
        </li> */}
      </ol>
      <p>
        <strong>Mac:</strong> Paste your signature into the{" "}
        <strong>Signature box (Ctrl+V or Command+V)</strong>, click on the
        clipboard that popped-up and select{" "}
        <strong>Keep Source Formatting</strong> pasting option and press{" "}
        <strong>OK</strong>.
      </p>
      <p>
        <strong>Windows:</strong> Right click the{" "}
        <strong>Edit Signature box</strong> and choose{" "}
        <strong>Keep Source Formatting</strong> pasting option and press{" "}
        <strong>OK</strong>.
      </p>
    </article>
  ),
  iphone: (
    <article>
      <ol>
        <li>
          Generate an email signature using <strong>Easy Signature</strong>{" "}
          editor.
        </li>
        <li>Open signature preview link on your iPhone/iPad.</li>
        <li>
          Tap on <strong>Copy to clipboard</strong> button.
        </li>
        <li>
          Go to your <strong>Settings</strong> on your iPhone/iPad &gt; Mail
          &gt; Signature.
        </li>
        <li>
          <strong>Paste</strong> your rich HTML signature. When you paste in the
          copied signature iOS will automatically strip it of formatting.
        </li>
        <li>
          Physically <strong>shake your iPhone/iPad</strong> to drop default
          styling of the iPhone/iPad.
        </li>
        <li>
          Tap <strong>Undo</strong>.
        </li>
        <li>
          You should see your new signature when you compose a new message on
          your iPhone/iPad now. Go back to the Mail app and try sending an email
          from the account that you installed the signature on.
        </li>
      </ol>
    </article>
  ),
  office365: (
    <article>
      <ol>
        <li>
          Generate an email signature using <strong>Easy Signature</strong>{" "}
          editor.
        </li>
        <li>
          Click on <strong>Copy to clipboard</strong> button.
        </li>
        <li>Go to Office365 or Outlook.com .</li>
        <li>
          In the top right, click{" "}
          <strong>Settings &gt; View full settings</strong>.
        </li>
        <li>
          Go to <strong>Mail &gt; Compose and reply</strong>.
        </li>
        <li>
          Paste your signature <strong>(Ctrl+V or Command+V)</strong> into the{" "}
          <strong>"Email signature"</strong> section.
        </li>
        <li>
          Choose "Compose messages in <strong>HTML</strong> format" in the{" "}
          <strong>"Message format"</strong> section.
        </li>
        <li>
          Click <strong>Save</strong> button.
        </li>
      </ol>
    </article>
  ),
  html: (
    <div>
      <p>
        {" "}
        Some email clients need email signatures as an HTML file or source code.{" "}
      </p>
      <div>
        <a href="#">
          <p> Copy source code </p>
        </a>
        <div>
          {/* <p>
            {" "}
            &lt;div&gt;&lt;table cellpadding="0" width="500"
            style="border-collapse:collapse;font-size:14.4px;"&gt;&lt;tr&gt;&lt;td
            style="margin:0.1px;padding:0"&gt;&lt;table cellpadding=" ...{" "}
          </p> */}
          <textarea id={classes.htmlCode} cols="30" rows="10"></textarea>
        </div>
      </div>
    </div>
  ),
};
