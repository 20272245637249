import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  // loading: false,
  generalData: {},
  contactData: {
    phone: "Phone",
    website: "Website",
    email: "Email",
    phone1: "",
    website1: "",
    email1: "",
    mobile1: "",
    address1: "",
    skype1: "",
  },
};

const generalSlice = createSlice({
  name: "general",
  initialState,
  reducers: {
    setGeneralData(state, action) {
      state.generalData[action.payload.name] = action.payload.value;
    },

    setContactData(state, action) {
      state.contactData[action.payload.name] = action.payload.value;
    },
    setAllData(state, action) {
      state.generalData = action.payload.generalData;
      state.contactData = action.payload.contactData;
      // console.log(state);
    },
    // setLoading(state, action) {
    //   state.loading = action.payload.loading;
    // },
    resetState() {
      return initialState;
    },
  },
});

export const generalSliceActions = generalSlice.actions;
export default generalSlice.reducer;
