import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { generalSliceActions } from "../../../../store/general-slice";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import CloseIcon from "@material-ui/icons/Close";
import AddIcon from "@material-ui/icons/Add";

import classes from "./General.module.css";

const General = ({ displayFields, setDisplayFields }) => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);

  const generalData = useSelector((state) => state.general.generalData);

  const contactData = useSelector((state) => state.general.contactData);

  const generalDataHandler = (event) => {
    dispatch(
      generalSliceActions.setGeneralData({
        name: event.target.name,
        value: event.target.value,
      })
    );
  };

  const contactDataHandler = (event) => {
    dispatch(
      generalSliceActions.setContactData({
        name: event.target.name,
        value: event.target.value,
      })
    );
  };

  const displayFieldsHandler = (event) => {
    const field = event.currentTarget.id;

    const uniqueValues = [...new Set([...displayFields, field])];
    setDisplayFields(uniqueValues);
    contactDataHandler({
      target: {
        name: field,
        value: field[0].toUpperCase() + field.slice(1),
      },
    });
  };

  const removeFieldsHandler = (event) => {
    const filteredArray = displayFields.filter(
      (field) => field !== event.currentTarget.id
    );
    setDisplayFields(filteredArray);
    contactDataHandler({
      target: {
        name: event.currentTarget.id,
        value: "",
      },
    });
  };
  console.log(displayFields);
  return (
    <div className={classes.main}>
      <div>
        <label htmlFor="name">Name</label>
        <TextField
          InputLabelProps={{
            shrink: true,
          }}
          size="small"
          id="demo-helper-text-aligned"
          label="Name"
          name="name"
          value={generalData?.name}
          onChange={generalDataHandler}
        />
      </div>
      <br />
      <div>
        <label htmlFor="company">Company</label>
        <TextField
          InputLabelProps={{
            shrink: true,
          }}
          size="small"
          id="demo-helper-text-aligned"
          label="Company"
          name="company"
          value={generalData?.company}
          onChange={generalDataHandler}
        />
      </div>
      <br />
      <div>
        <label htmlFor="position">Position</label>
        <TextField
          InputLabelProps={{
            shrink: true,
          }}
          size="small"
          id="demo-helper-text-aligned"
          label="Position"
          name="position"
          value={generalData?.position}
          onChange={generalDataHandler}
        />
      </div>
      <br />
      <div>
        <label htmlFor="department">Department</label>
        <TextField
          InputLabelProps={{
            shrink: true,
          }}
          size="small"
          id="demo-helper-text-aligned"
          label="Department"
          name="department"
          value={generalData?.department}
          onChange={generalDataHandler}
        />
      </div>
      <section>
        <hr className={classes.seperator} />
        <Typography variant="h6" marginBottom={"2rem"}>
          Contacts
        </Typography>
        <div className={classes["capsule-textfield"]}>
          {displayFields.map((field) => (
            <div key={field} className={classes["contact-field"]}>
              <TextField
                id="outlined-basic"
                InputLabelProps={{
                  shrink: true,
                }}
                label={`${field} title`}
                variant="outlined"
                name={field}
                value={contactData[field]}
                onChange={contactDataHandler}
              />
              <TextField
                id="outlined-basic"
                label={field}
                variant="outlined"
                name={`${field}1`}
                value={contactData[`${field}1`]}
                onChange={contactDataHandler}
              />
              <span
                onClick={removeFieldsHandler}
                id={field}
                className={classes.cross}
              >
                <CloseIcon />
              </span>
            </div>
          ))}
          <div className={classes.addfield}>
            <AddIcon />
            <FormControl sx={{ m: 1, minWidth: 120, paddingTop: "5px" }}>
              <InputLabel id="demo-controlled-open-select-label">
                Add Field
              </InputLabel>
              <Select
                labelId="demo-controlled-open-select-label"
                id="demo-controlled-open-select"
                open={open}
                onClose={() => setOpen(false)}
                onOpen={() => setOpen(true)}
                label="add-field"
                value={0}
              >
                <MenuItem value={10} onClick={displayFieldsHandler} id="phone">
                  Phone
                </MenuItem>
                <MenuItem
                  value={20}
                  onClick={displayFieldsHandler}
                  id="website"
                >
                  Website
                </MenuItem>
                <MenuItem value={30} onClick={displayFieldsHandler} id="email">
                  Email
                </MenuItem>
                <MenuItem value={40} onClick={displayFieldsHandler} id="mobile">
                  Mobile
                </MenuItem>
                <MenuItem
                  value={50}
                  onClick={displayFieldsHandler}
                  id="address"
                >
                  Address
                </MenuItem>
                <MenuItem value={60} onClick={displayFieldsHandler} id="skype">
                  Skype
                </MenuItem>
              </Select>
            </FormControl>
          </div>
        </div>
      </section>
    </div>
  );
};

export default General;
