import React from "react";
import { useSelector } from "react-redux";

import classes from "./Social.module.css";

const Social = () => {
  const socialHandles = useSelector((state) => state.social);
  const fontSize = useSelector((state) => state.design.fontSize);
  const tempColor = useSelector((state) => state.design.tempColor);
  const layout = useSelector((state) => state.design.layout);

  const checkSocialInputs = [
    socialHandles.facebook && socialHandles.facebook !== "https://fb.me/",
    socialHandles.twitter && socialHandles.twitter !== "https://twitter.com/",
    socialHandles.linkedIn &&
      socialHandles.linkedIn !== "http://www.linkedin.com/in/",
    socialHandles.github && socialHandles.github !== "https://github.com/",
    socialHandles.messenger && socialHandles.messenger !== "https://m.me/",
    socialHandles.whatsapp && socialHandles.whatsapp !== "https://wa.me/",
  ];

  // Checks if any true value in socialInputs
  const showSocialHandles = checkSocialInputs.find((element) => element);

  if (showSocialHandles) {
    if (layout === 1) {
      return (
        <td valign="middle" className={classes.social}>
          <table
            cellPadding="0"
            style={{
              borderCollapse: "collapse",
            }}
          >
            {checkSocialInputs[0] && (
              <tr>
                <td>
                  <a href={socialHandles.facebook} target="_blank">
                    <img
                      width="22"
                      // src="https://img.mysignature.io/s/0/f/c/0fc609bd-03b9-5421-b588-45131be92007.png?uid=486330"
                      src="../../../../../images/facebook.png"
                      alt="Facebook"
                      title="Facebook"
                    />
                    {/* <FacebookIcon fontSize="inherit" /> */}
                  </a>
                </td>
              </tr>
            )}
            {checkSocialInputs[1] && (
              <tr>
                <td>
                  <a href={socialHandles.twitter} target="_blank">
                    <img
                      width="22"
                      src="../../../../../images/twitter.png"
                      alt="Twitter"
                      title="Twitter"
                    />
                    {/* <TwitterIcon fontSize="inherit" /> */}
                  </a>
                </td>
              </tr>
            )}
            {checkSocialInputs[2] && (
              <tr>
                <td>
                  <a href={socialHandles.linkedIn} target="_blank">
                    <img
                      width="22"
                      src="../../../../../images/linkedIn.png"
                      alt="LinkedIn"
                      title="LinkedIn"
                    />
                    {/* <LinkedInIcon fontSize="inherit" /> */}
                  </a>
                </td>
              </tr>
            )}
            {checkSocialInputs[3] && (
              <tr>
                <td>
                  <a href={socialHandles.github} target="_blank">
                    <img
                      width="22"
                      src="../../../../../images/github.png"
                      alt="Github"
                      title="Github"
                    />
                    {/* <GithubIcon fontSize="inherit" /> */}
                  </a>
                </td>
              </tr>
            )}
            {checkSocialInputs[4] && (
              <tr>
                <td>
                  <a href={socialHandles.messenger} target="_blank">
                    <img
                      width="22"
                      src="../../../../../images/messenger.png"
                      alt="Messenger"
                      title="Messenger"
                    />
                    {/* <FontAwesomeIcon icon={faSlack} /> */}
                  </a>
                </td>
              </tr>
            )}
            {checkSocialInputs[5] && (
              <tr>
                <td>
                  <a href={socialHandles.whatsapp} target="_blank">
                    <img
                      width="22"
                      src="../../../../../images/whatsapp.png"
                      alt="WhatsApp"
                      title="WhatsApp"
                    />
                    {/* <FontAwesomeIcon icon={faSlack} /> */}
                  </a>
                </td>
              </tr>
            )}
          </table>
        </td>
      );
    }
    if (
      layout === 2 ||
      layout === 3 ||
      layout === 4 ||
      layout === 5 ||
      layout === 6 ||
      layout === 7 ||
      layout === 9 ||
      layout === 10 ||
      layout === 12
    ) {
      return (
        <tr>
          <td
            valign="middle"
            style={{ paddingTop: layout === 3 ? "12px" : "9px" }}
            className={classes["social-layout2"]}
          >
            <table
              cellPadding="0"
              style={{
                borderCollapse: "collapse",
              }}
            >
              <tr>
                {checkSocialInputs[0] && (
                  <td>
                    <a href={socialHandles.facebook} target="_blank">
                      <img
                        width={+fontSize + 6}
                        src="../../../../../images/facebook.png"
                        alt="Facebook"
                        title="Facebook"
                      />
                      {/* <FacebookIcon fontSize="inherit" /> */}
                    </a>
                  </td>
                )}
                {checkSocialInputs[1] && (
                  <td>
                    <a href={socialHandles.twitter} target="_blank">
                      <img
                        width={+fontSize + 6}
                        src="../../../../../images/twitter.png"
                        alt="Twitter"
                        title="Twitter"
                      />
                      {/* <TwitterIcon fontSize="inherit" /> */}
                    </a>
                  </td>
                )}
                {checkSocialInputs[2] && (
                  <td>
                    <a href={socialHandles.linkedIn} target="_blank">
                      <img
                        width={+fontSize + 6}
                        src="../../../../../images/linkedIn.png"
                        alt="LinkedIn"
                        title="LinkedIn"
                      />
                      {/* <LinkedInIcon fontSize="inherit" /> */}
                    </a>
                  </td>
                )}
                {checkSocialInputs[3] && (
                  <td>
                    <a href={socialHandles.github} target="_blank">
                      <img
                        width={+fontSize + 6}
                        src="../../../../../images/github.png"
                        alt="Github"
                        title="Github"
                      />
                      {/* <GithubIcon fontSize="inherit" /> */}
                    </a>
                  </td>
                )}
                {checkSocialInputs[4] && (
                  <td>
                    <a href={socialHandles.messenger} target="_blank">
                      <img
                        width={+fontSize + 6}
                        src="../../../../../images/messenger.png"
                        alt="Messenger"
                        title="Messenger"
                      />
                      {/* <FontAwesomeIcon icon={faSlack} /> */}
                    </a>
                  </td>
                )}
                {checkSocialInputs[5] && (
                  <td>
                    <a href={socialHandles.whatsapp} target="_blank">
                      <img
                        width={+fontSize + 6}
                        src="../../../../../images/whatsapp.png"
                        alt="WhatsApp"
                        title="WhatsApp"
                      />
                      {/* <FontAwesomeIcon icon={faSlack} /> */}
                    </a>
                  </td>
                )}
              </tr>
            </table>
          </td>
        </tr>
      );
    }
    // if (layout === 8 || layout === 11) {
    //   return (
    //     <tr>
    //       <td
    //         colspan="2"
    //         style={{
    //           lineHeight: "0%",
    //           // backgroundColor: tempColor,
    //           // padding: "12px 6px",
    //           // width: "270px",
    //           // maxWidth: "100%",
    //         }}
    //       >
    //         <table
    //           cellpadding="0"
    //           cellspacing="0"
    //           style={{ lineHeight: 1.2 }}
    //           // align="right"
    //         >
    //           <tbody>
    //             <tr>
    //               <td style={{ backgroundColor: tempColor }}>
    //                 <table border="0" cellpadding="0" cellspacing="0">
    //                   <tbody>
    //                     <tr>
    //                       {checkSocialInputs[0] && (
    //                         <td
    //                           align="left"
    //                           style={{
    //                             padding: "5px",
    //                             textAlign: "center",

    //                             backgroundColor: tempColor,
    //                           }}
    //                         >
    //                           <a href={socialHandles.facebook} target="_blank">
    //                             <img
    //                               width={+fontSize + 6}
    //                               src="../../../../../images/facebook.png"
    //                               style={{ float: "left", border: "none" }}
    //                               border="0"
    //                             />
    //                           </a>
    //                         </td>
    //                       )}
    //                       {checkSocialInputs[1] && (
    //                         <td
    //                           align="left"
    //                           style={{
    //                             padding: "5px",
    //                             textAlign: "center",

    //                             backgroundColor: tempColor,
    //                           }}
    //                         >
    //                           <a href={socialHandles.twitter} target="_blank">
    //                             <img
    //                               width={+fontSize + 6}
    //                               // height="24"
    //                               src="../../../../../images/twitter.png"
    //                               style={{ float: "left", border: "none" }}
    //                               border="0"
    //                             />
    //                           </a>
    //                         </td>
    //                       )}
    //                       {checkSocialInputs[2] && (
    //                         <td
    //                           align="left"
    //                           style={{
    //                             padding: "5px",
    //                             textAlign: "center",

    //                             backgroundColor: tempColor,
    //                           }}
    //                         >
    //                           <a href={socialHandles.linkedIn} target="_blank">
    //                             <img
    //                               width={+fontSize + 6}
    //                               // height="24"
    //                               src="../../../../../images/linkedIn.png"
    //                               style={{ float: "left", border: "none" }}
    //                               border="0"
    //                             />
    //                           </a>
    //                         </td>
    //                       )}
    //                       {checkSocialInputs[3] && (
    //                         <td
    //                           align="left"
    //                           style={{
    //                             padding: "5px",
    //                             textAlign: "center",

    //                             backgroundColor: tempColor,
    //                           }}
    //                         >
    //                           <a href={socialHandles.github} target="_blank">
    //                             <img
    //                               width={+fontSize + 6}
    //                               // height="24"
    //                               src="../../../../../images/github.png"
    //                               style={{ float: "left", border: "none" }}
    //                               border="0"
    //                             />
    //                           </a>
    //                         </td>
    //                       )}
    //                       {checkSocialInputs[4] && (
    //                         <td
    //                           align="left"
    //                           style={{
    //                             padding: "5px",
    //                             textAlign: "center",

    //                             backgroundColor: tempColor,
    //                           }}
    //                         >
    //                           <a href={socialHandles.messenger} target="_blank">
    //                             <img
    //                               width={+fontSize + 6}
    //                               // height="24"
    //                               src="../../../../../images/messenger.png"
    //                               style={{ float: "left", border: "none" }}
    //                               border="0"
    //                             />
    //                           </a>
    //                         </td>
    //                       )}
    //                       {checkSocialInputs[5] && (
    //                         <td
    //                           align="left"
    //                           style={{
    //                             padding: "5px",
    //                             textAlign: "center",

    //                             backgroundColor: tempColor,
    //                           }}
    //                         >
    //                           <a href={socialHandles.whatsapp} target="_blank">
    //                             <img
    //                               width={+fontSize + 6}
    //                               // height="24"
    //                               src="../../../../../images/whatsapp.png"
    //                               style={{ float: "left", border: "none" }}
    //                               border="0"
    //                             />
    //                           </a>
    //                         </td>
    //                       )}
    //                     </tr>
    //                   </tbody>
    //                 </table>
    //               </td>
    //             </tr>
    //           </tbody>
    //         </table>
    //       </td>
    //     </tr>
    //   );
    // }

    if (layout === 8 || layout === 11) {
      return (
        <tr>
          <td
            colspan="2"
            style={{
              backgroundColor: tempColor,
              padding: "15px 6px",
              lineHeight: "0%",
              // width: "270px",
              // maxWidth: "100%",
              width: "100%",
            }}
          >
            <table
              cellpadding="0"
              cellspacing="0"
              style={{ lineHeight: 1.2, float: "right" }}
              align="right"
            >
              <tbody>
                <tr>
                  <td>
                    <table border="0" cellpadding="0" cellspacing="0">
                      <tbody>
                        <tr>
                          {checkSocialInputs[0] && (
                            <td
                              align="left"
                              style={{
                                paddingRight: "6px",
                                textAlign: "center",
                                paddingTop: "0",
                              }}
                            >
                              <a href={socialHandles.facebook} target="_blank">
                                <img
                                  width={+fontSize + 6}
                                  src="../../../../../images/facebook.png"
                                  style={{ float: "left", border: "none" }}
                                  border="0"
                                />
                              </a>
                            </td>
                          )}
                          {checkSocialInputs[1] && (
                            <td
                              align="left"
                              style={{
                                paddingRight: "6px",
                                textAlign: "center",
                                paddingTop: "0",
                              }}
                            >
                              <a href={socialHandles.twitter} target="_blank">
                                <img
                                  width={+fontSize + 6}
                                  // height="24"
                                  src="../../../../../images/twitter.png"
                                  style={{ float: "left", border: "none" }}
                                  border="0"
                                />
                              </a>
                            </td>
                          )}
                          {checkSocialInputs[2] && (
                            <td
                              align="left"
                              style={{
                                paddingRight: "6px",
                                textAlign: "center",
                                paddingTop: "0",
                              }}
                            >
                              <a href={socialHandles.linkedIn} target="_blank">
                                <img
                                  width={+fontSize + 6}
                                  // height="24"
                                  src="../../../../../images/linkedIn.png"
                                  style={{ float: "left", border: "none" }}
                                  border="0"
                                />
                              </a>
                            </td>
                          )}
                          {checkSocialInputs[3] && (
                            <td
                              align="left"
                              style={{
                                paddingRight: "6px",
                                textAlign: "center",
                                paddingTop: "0",
                              }}
                            >
                              <a href={socialHandles.github} target="_blank">
                                <img
                                  width={+fontSize + 6}
                                  // height="24"
                                  src="../../../../../images/github.png"
                                  style={{ float: "left", border: "none" }}
                                  border="0"
                                />
                              </a>
                            </td>
                          )}
                          {checkSocialInputs[4] && (
                            <td
                              align="left"
                              style={{
                                paddingRight: "6px",
                                textAlign: "center",
                                paddingTop: "0",
                              }}
                            >
                              <a href={socialHandles.messenger} target="_blank">
                                <img
                                  width={+fontSize + 6}
                                  // height="24"
                                  src="../../../../../images/messenger.png"
                                  style={{ float: "left", border: "none" }}
                                  border="0"
                                />
                              </a>
                            </td>
                          )}
                          {checkSocialInputs[5] && (
                            <td
                              align="left"
                              style={{
                                paddingRight: "6px",
                                textAlign: "center",
                                paddingTop: "0",
                              }}
                            >
                              <a href={socialHandles.whatsapp} target="_blank">
                                <img
                                  width={+fontSize + 6}
                                  // height="24"
                                  src="../../../../../images/whatsapp.png"
                                  style={{ float: "left", border: "none" }}
                                  border="0"
                                />
                              </a>
                            </td>
                          )}
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
              </tbody>
            </table>
          </td>
        </tr>
      );
    }
  }
};

export default Social;
