import React, { useState } from "react";
import General from "./InputTypes/General";
import Images from "./InputTypes/Images";
import InputHeader from "./InputHeader/InputHeader";
import Social from "./InputTypes/Social";
import Design from "./InputTypes/Design";
import UploadCSV from "./InputTypes/UploadCSV";
// import { useSelector } from "react-redux";
// import { useEffect } from "react";

import classes from "./Inputs.module.css";

const Inputs = ({ displayFields, setDisplayFields, singleCreation = true }) => {
  const [switchInputs, setSwitchInputs] = useState(
    singleCreation ? "general" : "csv"
  );

  const [bulkData, setBulkData] = useState(null);

  // const [displayFields, setDisplayFields] = useState([
  //   "phone",
  //   "website",
  //   "email",
  // ]);
  // const [displayFields, setDisplayFields] = useState(["phone"]);

  // useEffect(() => {
  //   const timer = setTimeout(() => {
  //     if (contactData.length > 0) {
  //       setDisplayFields(
  //         Object.keys(contactData).filter(
  //           (data) => data[data.length - 1] !== "1" && contactData[data]
  //         )
  //       );
  //     }
  //     console.log("initialized");
  //   }, 3000);

  //   return () => clearTimeout(timer);
  // }, []);

  return (
    <div className={classes.card}>
      <div className={classes.header}>
        <InputHeader
          switchInputs={switchInputs}
          setSwitchInputs={setSwitchInputs}
          singleCreation={singleCreation}
        />
      </div>

      {switchInputs === "general" && (
        <General
          displayFields={displayFields}
          setDisplayFields={setDisplayFields}
        />
      )}
      {switchInputs === "images" && <Images />}
      {switchInputs === "social" && <Social />}
      {switchInputs === "design" && <Design />}
      {switchInputs === "csv" && (
        <UploadCSV
          bulkData={bulkData}
          setBulkData={setBulkData}
          setDisplayFields={setDisplayFields}
        />
      )}
    </div>
  );
};
export default Inputs;
