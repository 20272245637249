import React, { useState, useEffect } from "react";
import { imageSliceActions } from "../../../../../store/image-slice";
import { useDispatch, useSelector } from "react-redux";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Button from "@mui/material/Button";
import CropperJS from "cropperjs";
// import Cropper from "react-easy-crop";
// import Typography from "@mui/material/Typography";

import classes from "./CropModal.module.css";
import "./Cropper.css";

// const style = {
//   position: "relative",
//   top: "50%",
//   left: "50%",
//   transform: "translate(-50%, -50%)",
//   width: 400,
//   height: 500,
//   p: 0,
//   borderRadius: "20px",
//   boxShadow: 24,
//   bgcolor: "background.paper",
// };

const CropModal = () => {
  const dispatch = useDispatch();
  const imageURL = useSelector((state) => state.image.imageURL);
  const [open, setOpen] = useState(false);
  const [cropShape, setCropShape] = useState(null);
  const [cropper, setCropper] = useState("");
  //   const [crop, setCrop] = useState({ x: 0, y: 0 });
  //   const [zoom, setZoom] = useState(1);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  //   const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
  //     console.log(croppedArea, croppedAreaPixels);
  //   }, []);
  const setCropShapeHandler = (event) => {
    setCropShape((prevState) => !prevState);
    const cropperViewBox = document.querySelector(".cropper-view-box");
    const cropperFace = document.querySelector(".cropper-face");
    if (cropShape) {
      cropperViewBox.style.borderRadius = "0px";
      cropperFace.style.borderRadius = "0px";
    } else {
      cropperViewBox.style.borderRadius = "50%";
      cropperFace.style.borderRadius = "50%";
    }
  };

  // CropperJS
  function getRoundedCanvas(sourceCanvas) {
    var canvas = document.createElement("canvas");
    var context = canvas.getContext("2d");
    var width = sourceCanvas.width;
    var height = sourceCanvas.height;

    canvas.width = width;
    canvas.height = height;
    context.imageSmoothingEnabled = true;
    context.drawImage(sourceCanvas, 0, 0, width, height);
    context.globalCompositeOperation = "destination-in";
    context.beginPath();
    context.arc(
      width / 2,
      height / 2,
      Math.min(width, height) / 2,
      0,
      2 * Math.PI,
      true
    );
    context.fill();
    return canvas;
  }

  useEffect(() => {
    // console.log("In UseEffect");
    // window.addEventListener("DOMContentLoaded", function () {
    if (open) {
      setTimeout(() => {
        // console.log("BEGIN");
        var image = document.getElementById("image");
        // var button = document.getElementById("button");
        // console.log("BUTTON IS HERE: ", button);
        var croppable = false;

        // var cropper = new CropperJS(image, {
        //   //   aspectRatio: 0,
        //   aspectRatio: 1,
        //   viewMode: 1,
        //   ready: function () {
        //     croppable = true;
        //     console.log("Reacy");
        //   },
        // });
        setCropper(
          new CropperJS(image, {
            //   aspectRatio: 0,
            aspectRatio: 1,
            viewMode: 1,
            ready: function () {
              // croppable = true;
              setCropShape(false);
              // console.log("Reacy");
            },
          })
        );
        // console.log("Cropper:", cropper);

        // button.onclick = function () {
        //   var croppedCanvas;
        //   var roundedCanvas;
        //   // var roundedImage;

        //   if (!croppable) {
        //     console.log("Not croppable");
        //     return;
        //   }

        //   // Crop
        //   croppedCanvas = cropper.getCroppedCanvas();

        //   // Round
        //   roundedCanvas = getRoundedCanvas(croppedCanvas);

        //   // Show
        //   cropShape
        //     ? roundedCanvas.toBlob((result) => {
        //         const imageURL = URL.createObjectURL(result);
        //         dispatch(
        //           imageSliceActions.setImageURL({
        //             url: imageURL,
        //             image: result,
        //           })
        //         );
        //         setOpen(false);
        //       })
        //     : croppedCanvas.toBlob((result) => {
        //         const imageURL = URL.createObjectURL(result);
        //         dispatch(
        //           imageSliceActions.setImageURL({
        //             url: imageURL,
        //             image: result,
        //           })
        //         );
        //         setOpen(false);
        //       });
        //   // roundedImage = document.createElement("img");
        //   // roundedImage.src = roundedCanvas.toDataURL();
        //   // result.innerHTML = "";
        //   // result.appendChild(roundedImage);
        // };
      }, 100);
    }
    // });
  }, [open]);

  const onCropHandler = () => {
    var croppedCanvas;
    var roundedCanvas;
    // var roundedImage;

    // if (!croppable) {
    //   console.log("Not croppable");
    //   return;
    // }

    // Crop
    croppedCanvas = cropper.getCroppedCanvas();

    // Round
    roundedCanvas = getRoundedCanvas(croppedCanvas);

    // Show
    cropShape
      ? roundedCanvas.toBlob((result) => {
          const imageURL = URL.createObjectURL(result);
          dispatch(
            imageSliceActions.setImageURL({
              url: imageURL,
              image: result,
            })
          );
          setOpen(false);
        })
      : croppedCanvas.toBlob((result) => {
          const imageURL = URL.createObjectURL(result);
          dispatch(
            imageSliceActions.setImageURL({
              url: imageURL,
              image: result,
            })
          );
          setOpen(false);
        });
  };

  return (
    <div>
      <Button
        sx={{ margin: "2rem 0 0 0", padding: "0" }}
        className={classes["crop-button"]}
        onClick={handleOpen}
      >
        Crop Image
      </Button>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Box
            sx={{ boxShadow: 24, bgcolor: "background.paper" }}
            className={classes["crop-screen"]}
          >
            <div className={classes["crop-container-main"]}>
              <div className={classes["crop-controls"]}>
                <h3>Controls</h3>
                <div>
                  <div className={classes["shape-controls"]}>
                    <span style={{ marginLeft: 0 }}>Rectangle</span>
                    <label className={classes["switch"]}>
                      <input
                        checked={cropShape}
                        onChange={setCropShapeHandler}
                        type="checkbox"
                        disabled={cropShape === null ? true : false}
                      />
                      <span
                        className={`${classes["slider"]} ${classes.round}`}
                      ></span>
                    </label>
                    <span>Rounded</span>
                  </div>
                  <Button
                    sx={{ display: "block", marginTop: "1rem" }}
                    variant="contained"
                    id="button"
                    onClick={onCropHandler}
                    disabled={cropShape === null ? true : false}
                  >
                    Crop
                  </Button>
                </div>
              </div>

              <div className="container">
                {/* <h1>Crop a round image</h1> */}
                <h3>Image</h3>
                <div>
                  <img
                    id="image"
                    //   src="https://firebasestorage.googleapis.com/v0/b/easy-signature-40115.appspot.com/o/images%2F26wKpA1JpqRWgiV1xX7GJdDFIvi2%2Fef8b508c-36e8-46ec-903d-4387f84b1100%2Flogo?alt=media&token=37293736-7a2b-46f0-85b9-42f3e754f6f5"
                    src={imageURL}
                    alt="Picture"
                    className="cropper-hidden"
                  />
                  {/* <div
                  className="cropper-container cropper-bg"
                  touch-action="none"
                  style={{ width: "640px", height: "364px" }}
                >
                  <div className="cropper-wrap-box">
                    <div
                      className="cropper-canvas"
                      style={{
                        width: "640px",
                        height: "360px",
                        transform: "translateY(2px)",
                      }}
                    >
                      <img
                        // src="https://firebasestorage.googleapis.com/v0/b/easy-signature-40115.appspot.com/o/images%2F26wKpA1JpqRWgiV1xX7GJdDFIvi2%2Fef8b508c-36e8-46ec-903d-4387f84b1100%2Flogo?alt=media&token=37293736-7a2b-46f0-85b9-42f3e754f6f5"
                        src="../../../../../../images/first_adv.png"
                        alt="Picture"
                        className="cropper-hide"
                        style={{
                          width: "640px",
                          height: "360px",
                          transform: "none",
                        }}
                      />
                    </div>
                  </div>
                  <div
                    className="cropper-drag-box cropper-crop cropper-modal"
                    data-cropper-action="crop"
                  ></div>
                  <div
                    className="cropper-crop-box"
                    style={{
                      width: "288px",
                      height: "288px",
                      transform: "translateX(262.4px) translateY(27.6px)",
                    }}
                  >
                    <span className="cropper-view-box">
                      <img
                        // src="https://firebasestorage.googleapis.com/v0/b/easy-signature-40115.appspot.com/o/images%2F26wKpA1JpqRWgiV1xX7GJdDFIvi2%2Fef8b508c-36e8-46ec-903d-4387f84b1100%2Flogo?alt=media&token=37293736-7a2b-46f0-85b9-42f3e754f6f5"
                        src="../../../../../../images/first_adv.png"
                        alt="Picture"
                        style={{
                          width: "640px",
                          height: "360px",
                          transform: "translateX(-262.4px) translateY(-25.6px)",
                        }}
                      />
                    </span>
                    <span className="cropper-dashed dashed-h"></span>
                    <span className="cropper-dashed dashed-v"></span>
                    <span className="cropper-center"></span>
                    <span
                      className="cropper-face cropper-move"
                      data-cropper-action="all"
                    ></span>
                    <span
                      className="cropper-line line-e"
                      data-cropper-action="e"
                    ></span>
                    <span
                      className="cropper-line line-n"
                      data-cropper-action="n"
                    ></span>
                    <span
                      className="cropper-line line-w"
                      data-cropper-action="w"
                    ></span>
                    <span
                      className="cropper-line line-s"
                      data-cropper-action="s"
                    ></span>
                    <span
                      className="cropper-point point-e"
                      data-cropper-action="e"
                    ></span>
                    <span
                      className="cropper-point point-n"
                      data-cropper-action="n"
                    ></span>
                    <span
                      className="cropper-point point-w"
                      data-cropper-action="w"
                    ></span>
                    <span
                      className="cropper-point point-s"
                      data-cropper-action="s"
                    ></span>
                    <span
                      className="cropper-point point-ne"
                      data-cropper-action="ne"
                    ></span>
                    <span
                      className="cropper-point point-nw"
                      data-cropper-action="nw"
                    ></span>
                    <span
                      className="cropper-point point-sw"
                      data-cropper-action="sw"
                    ></span>
                    <span
                      className="cropper-point point-se"
                      data-cropper-action="se"
                    ></span>
                  </div>
                </div> */}
                </div>
                {/* <h3>Result</h3> */}
                {/* <p>
                <button type="button" id="button">
                  Crop
                </button>
              </p> */}
                {/* <div id="result"></div> */}
              </div>
            </div>

            {/* <div className={classes["crop-controls"]}>
              <input
                type="range"
                value={zoom}
                min={1}
                max={3}
                step={0.1}
                aria-labelledby="Zoom"
                onChange={(e) => {
                  setZoom(e.target.value);
                }}
                className={classes["zoom-range"]}
              />
            </div> */}
          </Box>
        </Fade>
      </Modal>
    </div>
  );
};

export default CropModal;
