import React, { useState, useEffect } from "react";
import Inputs from "./Inputs/Inputs";
import Preview from "./Preview/Preview";
import Guide from "../GuidePreview/Guide";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setImageBySid } from "../../store/image-actions";
import { setDataBySid } from "../../store/general-actions";
// import { getDownloadURL, getStorage, ref as sRef } from "firebase/storage";

import classes from "./Designer.module.css";
// import { imageSliceActions } from "../../store/image-slice";
// import { child, get, getDatabase, ref } from "firebase/database";

const Designer = (props) => {
  const dispatch = useDispatch();
  const uId = useSelector((state) => state.auth.id);
  const sId = useParams()?.sid;
  const [displayFields, setDisplayFields] = useState([
    "phone",
    "website",
    "email",
  ]);

  useEffect(() => {
    if (sId) {
      dispatch(setImageBySid(uId, sId));
      dispatch(setDataBySid(uId, sId, setDisplayFields));
      // setDisplayFields(displayFieldsTemp);
    }
  }, [sId]);

  return (
    <div className={classes.designer}>
      {props.guide ? (
        <Guide />
      ) : (
        <Inputs
          displayFields={displayFields}
          setDisplayFields={setDisplayFields}
        />
      )}

      {/* <table>
        <tr>
          <td> */}
      <Preview displayFields={displayFields} guide={props?.guide} />
      {/* </td>
        </tr>
      </table> */}
    </div>
    // <div className={classes.save}>
    //   <button>Hello</button>
    // </div>
  );
};

export default Designer;
