import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import generalSlice from "./general-slice";
import imageSlice from "./image-slice";
import socialSlice from "./social-slice";
import designSlice from "./design-slice";
import authSlice from "./auth-slice";

const customizedMiddleware = getDefaultMiddleware({
  serializableCheck: false,
});

const store = configureStore({
  reducer: {
    general: generalSlice,
    image: imageSlice,
    social: socialSlice,
    design: designSlice,
    auth: authSlice,
  },
  // middleware: (getDefaultMiddleware) => getDefaultMiddleware(),
});

export default store;
