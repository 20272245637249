import React, { useState } from "react";
import Inputs from "../Designer/Inputs/Inputs";
import Preview from "../Designer/Preview/Preview";

import classes from "./BulkDesigner.module.css";
import BulkInputs from "./BulkInputs/BulkInputs";

const BulkDesigner = () => {
  // display field state comes here
  const [displayFields, setDisplayFields] = useState([
    "phone",
    "website",
    "email",
  ]);

  return (
    <div className={classes.designer}>
      {/* <BulkInputs /> */}
      <Inputs
        displayFields={null}
        setDisplayFields={setDisplayFields}
        singleCreation={false}
      />
      <Preview displayFields={displayFields} bulkCreation={true} />
    </div>
  );
};

export default BulkDesigner;
