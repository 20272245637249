import React, { Fragment, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, Routes } from "react-router-dom";
import PreviewPage from "./pages/PreviewPage";
import SignaturePage from "./pages/SignaturePage";
import Page404 from "./pages/Page404";
import DashboardPage from "./pages/DashboardPage";
import AuthPage from "./pages/AuthPage";
import BulkSignaturePage from "./pages/BulkSignaturePage";
import LandingPage from "./pages/LandingPage";
import ProfilePage from "./pages/ProfilePage";
import PrivacyPolicy from "./components/Misc/PrivacyPolicy";
import TermServices from "./components/Misc/TermServices";
import Navbar from "./components/UIElements/Navbar";
import Footer from "./components/UIElements/Footer";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { authSliceActions } from "./store/auth-slice";

import "./App.css";

function App() {
  const auth = getAuth();
  const dispatch = useDispatch();
  const isLoggedIn = useSelector((state) => state.auth.id);
  const featuresRef = useRef();
  const benefitsRef = useRef();
  // const navigate = useNavigate();
  // const [loading, setLoading] = useState(false);

  onAuthStateChanged(auth, (user) => {
    if (user && !isLoggedIn && user.emailVerified) {
      // setLoading(true);
      // console.log(user);

      const { uid, displayName, email } = user;
      const number = localStorage.getItem("number");

      dispatch(
        authSliceActions.signInUser({
          id: uid,
          name: displayName,
          email: email,
          number: number,
        })
      );

      // setLoading(false);

      // navigate("/dashboard");

      // console.log(user.displayName + " Signed In");
    }
  });

  // if (loading) {
  //   return <LoadingPage />;
  // }

  return (
    <Fragment>
      <Navbar benefitsRef={benefitsRef} featuresRef={featuresRef} />
      <Routes>
        <Route
          path="/"
          element={
            <LandingPage benefitsRef={benefitsRef} featuresRef={featuresRef} />
          }
        />
        <Route path="/login/" element={<AuthPage />} />
        <Route path="/signup/" element={<AuthPage isLogin={false} />} />
        <Route path="/privacy-policy/" element={<PrivacyPolicy />} />
        <Route path="/terms-and-services/" element={<TermServices />} />
        {isLoggedIn && (
          <Fragment>
            <Route path="/profile/" element={<ProfilePage />} />
            <Route path="/dashboard/" element={<DashboardPage />} />
            <Route path="/preview/:sid" element={<PreviewPage />} />
            <Route path="/edit/:sid" element={<SignaturePage />} />
            <Route path="/signature/" element={<SignaturePage />} />
            <Route path="/bulksignature/" element={<BulkSignaturePage />} />
            <Route
              path="/4097fe90-3f06-11ed-9660-8bb0fb210de9"
              element={
                <p style={{ textAlign: "center" }}>
                  Muhammad Usman Gauhar Khan
                </p>
              }
            />
          </Fragment>
        )}

        <Route path="*" element={<Page404 />} />
      </Routes>
      <Footer benefitsRef={benefitsRef} featuresRef={featuresRef} />
    </Fragment>
  );
}

export default App;
