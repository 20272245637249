import { Button } from "@mui/material";
import React, { Fragment } from "react";

import classes from "./InputHeader.module.css";

const InputHeader = ({ switchInputs, setSwitchInputs, singleCreation }) => {
  return (
    <Fragment>
      <div className={classes.buttons}>
        {!singleCreation && (
          <Button
            variant="outlined"
            size="large"
            className={switchInputs === "csv" ? classes.activeButton : ""}
            onClick={() => setSwitchInputs("csv")}
          >
            <span>Upload CSV</span>
          </Button>
        )}
        {singleCreation && (
          <Button
            variant="outlined"
            size="large"
            className={switchInputs === "general" ? classes.activeButton : ""}
            onClick={() => setSwitchInputs("general")}
          >
            <span>General</span>
          </Button>
        )}
        <Button
          variant="outlined"
          size="large"
          className={switchInputs === "images" ? classes.activeButton : ""}
          onClick={() => setSwitchInputs("images")}
        >
          <span>Images</span>
        </Button>
        {singleCreation && (
          <Button
            variant="outlined"
            size="large"
            className={switchInputs === "social" ? classes.activeButton : ""}
            onClick={() => setSwitchInputs("social")}
          >
            <span>Social</span>
          </Button>
        )}
        {/* <Button variant="outlined" size="large">
          <span>Addons</span>
        </Button> */}
        <Button
          variant="outlined"
          size="large"
          className={switchInputs === "design" ? classes.activeButton : ""}
          onClick={() => setSwitchInputs("design")}
        >
          <span>Design</span>
        </Button>
      </div>
    </Fragment>
  );
};

export default InputHeader;
