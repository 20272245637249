import React, { useState, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import PhoneInput from "react-phone-number-input";
import {
  setNewUserNameHandler,
  setNewUserNumberHandler,
  setNewUserPasswordHandler,
} from "../../store/auth-actions";
import { Alert, TextField } from "@mui/material";
import EditIcon from "@material-ui/icons/Edit";

import classes from "./Profile.module.css";
import "react-phone-number-input/style.css";

const Profile = () => {
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);

  const [name, setName] = useState(auth.name);
  const [number, setNumber] = useState(auth.number);
  const [currentPassword, setCurrentPassword] = useState();
  const [NewPassword, setNewPassword] = useState();
  const [isEditing, setIsEditing] = useState({
    name: false,
    password: false,
    number: false,
  });

  const editingHandler = (event) => {
    const id = event.currentTarget.id;
    setIsEditing((prevState) => ({
      ...prevState,
      [id]: true,
    }));
  };

  const setNewNameHandler = () => {
    if (!name.trim() || name === auth.name) {
      // console.log("Nice try!");
    } else {
      dispatch(setNewUserNameHandler(name));
    }
    setIsEditing((prevState) => ({
      ...prevState,
      name: false,
    }));
  };

  const setNewNumberHandler = () => {
    if (!number.trim() || number === auth.number) {
      // console.log("Smart Ass!");
    } else {
      dispatch(setNewUserNumberHandler(number));
    }
    setIsEditing((prevState) => ({
      ...prevState,
      number: false,
    }));
  };

  const setNewPasswordHandler = async (event) => {
    event.preventDefault();

    const response = await dispatch(
      setNewUserPasswordHandler(currentPassword, NewPassword)
    );

    if (response) {
      setIsEditing((prevState) => ({
        ...prevState,
        password: false,
      }));
    }
    // console.log(currentPassword, NewPassword);
  };

  return (
    <div className={classes.card}>
      <div>
        <div>
          <h2>Profile Information</h2>
          <div className={classes.information}>
            <p>
              <span>Name:</span>
              {isEditing.name ? (
                <Fragment>
                  <TextField
                    //   className={classes.value}
                    id="outlined-basic"
                    // defaultValue={auth.name}
                    value={name}
                    onChange={(event) => setName(event.target.value)}
                    //   label="Outlined"
                    variant="outlined"
                  />
                  <button onClick={setNewNameHandler}>Save</button>
                </Fragment>
              ) : (
                <Fragment>
                  <span className={classes.value}>
                    {name ? name : auth.name}
                  </span>
                  <span
                    id="name"
                    onClick={editingHandler}
                    className={classes.value}
                  >
                    <EditIcon />
                  </span>
                </Fragment>
              )}
            </p>
            <p>
              <span>Email:</span>
              <span className={classes.value}>{auth.email}</span>
            </p>
            <p className={classes.phoneinput}>
              <span>Phone:</span>
              {isEditing.number ? (
                <Fragment>
                  {/* <TextField
                    //   className={classes.value}
                    id="outlined-basic"
                    value={number}
                    onChange={(event) => setNumber(event.target.value)}
                    //   label="Outlined"
                    variant="outlined"
                  /> */}
                  {/* <span className={classes.phoneinput}> */}
                  <PhoneInput
                    international
                    countryCallingCodeEditable={false}
                    defaultCountry="PK"
                    placeholder="Enter phone number"
                    value={number}
                    onChange={setNumber}
                    // country="PK"
                  />
                  {/* </span> */}

                  <button onClick={setNewNumberHandler}>Save</button>
                </Fragment>
              ) : (
                <Fragment>
                  <span className={classes.value}>
                    {auth.number ? auth.number : "-"}
                  </span>
                  <span
                    id="number"
                    onClick={editingHandler}
                    className={classes.value}
                  >
                    <EditIcon />
                  </span>
                </Fragment>
              )}
            </p>
          </div>
        </div>
        <div className={classes.password}>
          <h2>Password</h2>

          {isEditing.password ? (
            <form onSubmit={setNewPasswordHandler}>
              <TextField
                // className={classes.value}
                // value={number}
                label="Current Password"
                // id="outlined-basic"
                onChange={(event) => setCurrentPassword(event.target.value)}
                variant="outlined"
                type="password"
                required
              />
              <TextField
                // className={classes.value}
                // value={number}
                label="New Password"
                // id="outlined-basic"
                onChange={(event) => setNewPassword(event.target.value)}
                variant="outlined"
                type="password"
                required
              />
              <button type="submit">Save</button>
            </form>
          ) : (
            <p>
              <span>••••••••••••••</span>
              <span>
                <span
                  id="password"
                  onClick={editingHandler}
                  className={classes.value}
                >
                  <EditIcon />
                </span>
              </span>
            </p>
          )}
        </div>
      </div>
      <div>
        {auth.error && (
          <Alert className={classes.error} severity="error">
            {auth.error}
          </Alert>
        )}
        {auth.message && (
          <Alert className={classes.error} severity="success">
            {auth.message}
          </Alert>
        )}
      </div>
    </div>
  );
};

export default Profile;
