import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { socialSliceActions } from "../../../../store/social-slice";
import { TextField } from "@mui/material";
import FacebookIcon from "@material-ui/icons/Facebook";
import TwitterIcon from "@material-ui/icons/Twitter";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import GithubIcon from "@material-ui/icons/GitHub";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebookMessenger } from "@fortawesome/free-brands-svg-icons";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";

import classes from "./Social.module.css";

const Social = () => {
  const dispatch = useDispatch();
  const socialHandles = useSelector((state) => state.social);

  const socialHandlesHandler = (event) => {
    dispatch(
      socialSliceActions.setSocialHandles({
        name: event.target.name,
        value: event.target.value,
      })
    );
  };

  return (
    <div className={classes.main}>
      <div className={classes["capsule-textfield"]}>
        <label htmlFor="facebook">
          <FacebookIcon fontSize="inherit" />
        </label>
        {/* Testing standard box */}
        {/* <div>
          https://fb.me/
          <TextField
            id="outlined-basic"
            variant="standard"
            name="facebook"
            onChange={socialHandlesHandler}
            value={socialHandles.facebook}
            // placeholder="https://www.facebook.com/id"
            // defaultValue="https://fb.me/"
          />
        </div> */}
        <TextField
          id="outlined-basic"
          placeholder="https://www.facebook.com/id"
          variant="outlined"
          name="facebook"
          onChange={socialHandlesHandler}
          // defaultValue="https://fb.me/"
          value={socialHandles.facebook}
        />
      </div>
      <div className={classes["capsule-textfield"]}>
        <label htmlFor="twitter">
          <TwitterIcon fontSize="inherit" />
        </label>
        <TextField
          id="outlined-basic"
          placeholder="https://www.twitter.com/id"
          variant="outlined"
          name="twitter"
          onChange={socialHandlesHandler}
          value={socialHandles.twitter}
        />
      </div>
      <div className={classes["capsule-textfield"]}>
        <label htmlFor="linkedIn">
          <LinkedInIcon fontSize="inherit" />
        </label>
        <TextField
          id="outlined-basic"
          placeholder="https://www.linkedin.com/company/id"
          variant="outlined"
          name="linkedIn"
          onChange={socialHandlesHandler}
          value={socialHandles.linkedIn}
        />
      </div>
      <div className={classes["capsule-textfield"]}>
        <label htmlFor="github">
          <GithubIcon fontSize="inherit" />
        </label>
        <TextField
          id="outlined-basic"
          placeholder="https://github.com/id"
          variant="outlined"
          name="github"
          onChange={socialHandlesHandler}
          value={socialHandles.github}
        />
      </div>
      <div className={classes["capsule-textfield"]}>
        <label htmlFor="messenger">
          <FontAwesomeIcon icon={faFacebookMessenger} />
        </label>
        <TextField
          id="outlined-basic"
          placeholder="https://team.slack.com"
          variant="outlined"
          name="messenger"
          onChange={socialHandlesHandler}
          value={socialHandles.messenger}
        />
      </div>
      <div className={classes["capsule-textfield"]}>
        <label htmlFor="whatsapp">
          <FontAwesomeIcon icon={faWhatsapp} />
        </label>
        <TextField
          id="outlined-basic"
          placeholder="https://team.slack.com"
          variant="outlined"
          name="whatsapp"
          onChange={socialHandlesHandler}
          value={socialHandles.whatsapp}
        />
      </div>
    </div>
  );
};

export default Social;
