import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  // getBlob,
  getDownloadURL,
  getStorage,
  ref as sRef,
} from "firebase/storage";
// import { child, get, getDatabase, ref } from "firebase/database";
// import { v4 as uuid } from "uuid";
import { IconButton, Menu, MenuItem } from "@mui/material";
import DownloadIcon from "@material-ui/icons/CloudDownload";
import CreateIcon from "@material-ui/icons/Create";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import MoreVertIcon from "@material-ui/icons/MoreVert";
// import BorderColorIcon from "@material-ui/icons/BorderColor";
// import VisibilityIcon from "@material-ui/icons/Visibility";

import classes from "./Signature.module.css";

const Signature = (props) => {
  const [previewURL, setPreviewURL] = useState("");
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const uid = useSelector((state) => state.auth.id);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  // const duplicateSignatureHandler = async () => {
  //   const newSigId = uuid();
  //   const storage = getStorage();
  //   const database = getDatabase();
  //   // const dbRef = ref(getDatabase());

  //   // Fetch the data
  //   const response = await get(
  //     child(ref(database), `users/${uid}/signatureData/${props.id}`)
  //   );
  //   const responseData = response.val();

  //   let pathReference = sRef(storage, `images/${uid}/${props.id}/logo`);
  //   const logoBlob = await getBlob(pathReference);

  //   pathReference = sRef(storage, `images/${uid}/${props.id}/preview`);
  //   const previewBlob = await getBlob(pathReference);

  //   // Set the data
  //   await set(ref(database, "users/" + uid + "/signatureData/" + newSigId), {
  //     id: newSigId,
  //     ...responseData,
  //   });

  //   const logoRef = sRef(storage, `images/${uid}/${newSigId}/logo`);
  //   await uploadBytes(logoRef, logoBlob);

  //   const previewRef = sRef(storage, `images/${uid}/${newSigId}/preview`);
  //   await uploadBytes(previewRef, previewBlob);
  // };

  useEffect(() => {
    const setPreview = async () => {
      const storage = getStorage();
      const pathReference = sRef(storage, `images/${uid}/${props.id}/preview`);
      const url = await getDownloadURL(pathReference);
      setPreviewURL(url);
      // console.log("Setting...");
    };

    setPreview();
  }, []);

  return (
    <div>
      <div className={classes.preview}>
        <img
          src={previewURL ? previewURL : "../../../images/previewSkeleton.png"}
          alt="structure"
        />
      </div>
      <div className={classes["footer-buttons"]}>
        <Link style={{ textDecoration: "none" }} to={`/preview/${props.id}`}>
          <DownloadIcon />
        </Link>
        <Link style={{ textDecoration: "none" }} to={`/edit/${props.id}`}>
          <CreateIcon />
        </Link>
        <a
          style={{ textDecoration: "none" }}
          onClick={() => props.duplicateSignatureHandler(props.id)}
        >
          <FileCopyIcon />
        </a>
        <div>
          <IconButton
            aria-label="more"
            id="long-button"
            aria-controls={open ? "long-menu" : undefined}
            aria-expanded={open ? "true" : undefined}
            aria-haspopup="true"
            onClick={handleClick}
          >
            <MoreVertIcon />
          </IconButton>
          <Menu
            id="long-menu"
            MenuListProps={{
              "aria-labelledby": "long-button",
            }}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            PaperProps={{
              style: {
                maxHeight: 48 * 4.5,
                width: "20ch",
              },
            }}
          >
            {/* <MenuItem>Invite Member</MenuItem>
            <MenuItem>Send by Email</MenuItem> */}
            <MenuItem onClick={() => props.deleteSignatureHandler(props.id)}>
              Delete Signature
            </MenuItem>
          </Menu>
        </div>
      </div>
    </div>
  );
};

export default Signature;
