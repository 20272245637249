import { BlockRounded } from "@material-ui/icons";
import React, { useState, useEffect, Fragment } from "react";
import { useSelector } from "react-redux";

import classes from "./Text.module.css";
const Text = ({ filteredContactData }) => {
  const generalData = useSelector((state) => state.general.generalData);
  const contactData = useSelector((state) => state.general.contactData);
  const designData = useSelector((state) => state.design);

  // const [filteredContactData, setFilteredContactData] = useState([]);

  // const { phone, website, email, mobile, skype, address } = contactData;

  // const font = `${designData.fontSize}px ${designData.fontFamily}`;
  // const fontLarger = `${designData.fontSize * 1.2}px ${designData.fontFamily}`;

  const fontSize = `${designData.fontSize}px`;
  const fontSizeLarger = `${designData.fontSize * 1.2}px`;
  const fontSizeLargest = `${designData.fontSize * 1.5}px`;
  const fontFamily = designData.fontFamily;

  // useEffect(() => {
  //   setFilteredContactData(
  //     Object.keys(contactData).filter(
  //       (contact) => contactData[contact] && contact[contact.length - 1] !== "1"
  //     )
  //   );

  //   // console.log(filteredContactData);
  // }, [phone, website, email, mobile, skype, address]);

  if (designData.layout === 1) {
    return (
      <td className={classes["td-text"]} valign="top">
        <table cellPadding="0" className={classes.text}>
          {generalData?.name && (
            <tr>
              <td
                style={{
                  fontSize: fontSizeLarger,
                  fontFamily,
                  color: designData.textColor,
                }}
              >
                {generalData.name}
              </td>
            </tr>
          )}

          {(generalData?.position ||
            generalData?.company ||
            generalData?.department) && (
            <tr>
              <td
                style={{
                  fontSize,
                  fontFamily,
                  padding: "4px 0px",
                  color: designData.textColor,
                }}
              >
                {generalData.position && `${generalData.position}`}
                {generalData.position &&
                  (generalData.company || generalData.department) &&
                  " | "}
                {generalData.company && `${generalData.company}`}
                {generalData.company && generalData.department && " | "}
                {generalData.department && `${generalData.department} `}
              </td>
            </tr>
          )}
          {filteredContactData.map((data) => (
            <tr key={data}>
              <td style={{ fontSize, fontFamily }}>
                <span
                  style={{ color: designData.tempColor }}
                  className={classes["contact-name"]}
                >
                  {contactData[data]}:{" "}
                </span>
                <span style={{ color: designData.textColor }}>
                  {contactData[`${data}1`]}
                </span>
              </td>
            </tr>
          ))}
          {/* </div> */}
        </table>
      </td>
    );
  }
  if (designData.layout === 2) {
    return (
      <Fragment>
        {generalData?.name && (
          <tr>
            {/* <td style={{ font }}> */}
            <td>
              <span
                style={{
                  fontSize: fontSizeLarger,
                  fontFamily,
                  color: designData.tempColor,
                }}
              >
                {generalData.name}
              </span>
              &nbsp;&nbsp;
              <span
                style={{ fontSize, fontFamily, color: designData.textColor }}
              >
                {generalData.position && `${generalData.position}`}
              </span>
            </td>
          </tr>
        )}
        {(generalData?.company || generalData?.department) && (
          <tr>
            <td
              style={{
                fontSize,
                fontFamily,
                padding: "5px 0px",
                color: designData.textColor,
              }}
            >
              {generalData.company && `${generalData.company}`}
              {generalData.company && generalData.department && " | "}
              {generalData.department && `${generalData.department} `}
            </td>
          </tr>
        )}
        {filteredContactData.map((data) => (
          <tr>
            <td style={{ fontSize, fontFamily, color: designData.textColor }}>
              <span className={classes["contact-name"]}>
                {contactData[data]}:{" "}
              </span>
              {contactData[`${data}1`]}
            </td>
          </tr>
        ))}
      </Fragment>
    );
  }
  if (designData.layout === 3) {
    return (
      <Fragment>
        {generalData?.name && (
          <tr>
            <td
              style={{
                fontSize: fontSizeLarger,
                fontFamily,
                color: designData.tempColor,
                padding: "0px 0px 8px",
                fontWeight: "bold",
              }}
            >
              {generalData.name}
            </td>
          </tr>
        )}
        {(generalData?.position ||
          generalData?.company ||
          generalData?.department) && (
          <tr>
            <td
              style={{
                fontSize,
                fontFamily,
                padding: "0px 0px 8px",
                color: designData.textColor,
              }}
            >
              {generalData.position && `${generalData.position}`}
              {generalData.position &&
                (generalData.company || generalData.department) &&
                " | "}
              {generalData.company && `${generalData.company}`}
              {generalData.company && generalData.department && " | "}
              {generalData.department && `${generalData.department} `}
            </td>
          </tr>
        )}
        <tr>
          <td
            style={{
              fontSize,
              fontFamily,
              padding: 0,
            }}
          >
            <table cellPadding="0">
              {filteredContactData.map((data) => (
                <tr key={data}>
                  <Fragment>
                    <td
                      style={{
                        color: designData.tempColor,
                        fontSize,
                        fontFamily,
                        padding: "0px 5px 0px 0px",
                        fontWeight: "bold",
                      }}
                      className={classes["contact-name"]}
                    >
                      {contactData[data]}:
                    </td>
                    <td
                      style={{
                        fontSize,
                        fontFamily,
                        color: designData.textColor,
                      }}
                    >
                      {contactData[`${data}1`]}
                    </td>
                  </Fragment>
                </tr>
              ))}
            </table>
          </td>
        </tr>
      </Fragment>
    );
  }
  if (designData.layout === 4) {
    return (
      <Fragment>
        {generalData?.name && (
          <tr>
            {/* <td style={{ font }}> */}
            <td>
              <span
                style={{
                  fontSize: fontSizeLarger,
                  fontFamily,
                  fontWeight: "600",
                  color: designData.tempColor,
                }}
              >
                {generalData.name}
              </span>
              <span
                style={{ fontSize, fontFamily, color: designData.textColor }}
              >
                &nbsp;|&nbsp;
                {generalData.position && `${generalData.position}`}
              </span>
            </td>
          </tr>
        )}
        {(generalData?.company || generalData?.department) && (
          <tr>
            <td
              style={{
                fontSize,
                fontFamily,
                padding: "5px 0px",
                color: designData.textColor,
              }}
            >
              {generalData.company && `| ${generalData.company}`}
              {generalData.company && generalData.department && " | "}
              {generalData.department && `${generalData.department} `}
            </td>
          </tr>
        )}
        {filteredContactData.map((data) => (
          <tr>
            <td style={{ fontSize, fontFamily, color: designData.textColor }}>
              <span>| </span>
              <span
                style={{ fontWeight: "600", color: designData.tempColor }}
                className={classes["contact-name"]}
              >
                {contactData[data]}:{" "}
              </span>
              <span style={{ color: designData.textColor }}>
                {contactData[`${data}1`]}
              </span>
            </td>
          </tr>
        ))}
      </Fragment>
    );
  }

  if (designData.layout === 5) {
    return (
      <Fragment>
        {generalData?.name && (
          <tr>
            <td
              style={{
                fontSize: fontSizeLarger,
                fontFamily,
                color: designData.tempColor,
                padding: "0px 0px 0px",
                fontWeight: "bold",
              }}
            >
              {generalData.name}
            </td>
          </tr>
        )}
        {(generalData?.position ||
          generalData?.company ||
          generalData?.department) && (
          <tr>
            <td
              style={{
                fontSize,
                fontFamily,
                padding: "0px 0px 8px",
                color: designData.textColor,
              }}
            >
              {generalData.position && `${generalData.position}`}
              {generalData.position &&
                (generalData.company || generalData.department) &&
                ", "}
              {generalData.company && `${generalData.company}`}
              {generalData.company && generalData.department && ", "}
              {generalData.department && `${generalData.department} `}
            </td>
          </tr>
        )}
        <tr>
          <td
            style={{
              fontSize,
              fontFamily,
              padding: 0,
            }}
          >
            <table cellPadding="0">
              <Fragment>
                <tr>
                  <td
                    valign="center"
                    style={{
                      color: designData.tempColor,
                      fontSize,
                      fontFamily,
                      padding: "0px 5px 0px 0px",
                      fontWeight: "bold",
                    }}
                    className={classes["contact-name"]}
                  >
                    {[...filteredContactData]
                      .splice(0, 3)
                      .map((data, index) =>
                        index > 0 ? (
                          <span key={data}>
                            &nbsp;|&nbsp;{contactData[`${data}1`]}
                          </span>
                        ) : (
                          <span key={data}>{contactData[`${data}1`]}</span>
                        )
                      )}
                  </td>
                </tr>
                {filteredContactData.length > 3 && (
                  <tr>
                    <td
                      valign="center"
                      style={{
                        paddingTop: "5px",
                        paddingBottom: "10px",
                        color: designData.tempColor,
                        fontSize,
                        fontFamily,
                        padding: "0px 5px 0px 0px",
                        fontWeight: "bold",
                      }}
                      className={classes["contact-name"]}
                    >
                      {[...filteredContactData]
                        .splice(3, 6)
                        .map((data, index) =>
                          index > 0 ? (
                            <span key={data}>
                              &nbsp;|&nbsp;{contactData[`${data}1`]}
                            </span>
                          ) : (
                            <span key={data}>{contactData[`${data}1`]}</span>
                          )
                        )}
                    </td>
                  </tr>
                )}
              </Fragment>
            </table>
          </td>
        </tr>
      </Fragment>
    );
  }
  if (designData.layout === 6) {
    return (
      <Fragment>
        {generalData?.name && (
          <tr>
            <td
              style={{
                fontSize: fontSizeLarger,
                fontFamily,
                color: designData.tempColor,
                padding: "0px 0px 0px",
                fontWeight: "bold",
              }}
            >
              {generalData.name}
            </td>
          </tr>
        )}
        {(generalData?.position ||
          generalData?.company ||
          generalData?.department) && (
          <tr>
            <td
              style={{
                fontSize,
                fontFamily,
                padding: "0px 0px 10px",
                borderBottom: `solid 2px ${designData.tempColor}`,
                color: designData.textColor,
              }}
            >
              {generalData.position && `${generalData.position}`}
              {generalData.position &&
                (generalData.company || generalData.department) &&
                ", "}
              {generalData.company && `${generalData.company}`}
              {generalData.company && generalData.department && ", "}
              {generalData.department && `${generalData.department} `}
            </td>
          </tr>
        )}
        <tr>
          <td
            style={{
              fontSize,
              fontFamily,
              padding: 0,
            }}
          >
            <table cellPadding="0">
              <Fragment>
                <tr>
                  <td
                    valign="center"
                    style={{
                      color: designData.tempColor,
                      fontSize,
                      fontFamily,
                      padding: "10px 5px 0px 0px",
                      fontWeight: "bold",
                    }}
                    className={classes["contact-name"]}
                  >
                    {[...filteredContactData]
                      .splice(0, 3)
                      .map((data, index) =>
                        index > 0 ? (
                          <span key={data}>
                            &nbsp;|&nbsp;{contactData[`${data}1`]}
                          </span>
                        ) : (
                          <span key={data}>{contactData[`${data}1`]}</span>
                        )
                      )}
                  </td>
                </tr>
                {filteredContactData.length > 3 && (
                  <tr>
                    <td
                      valign="center"
                      style={{
                        paddingTop: "5px",
                        paddingBottom: "10px",
                        color: designData.tempColor,
                        fontSize,
                        fontFamily,
                        padding: "0px 5px 0px 0px",
                        fontWeight: "bold",
                      }}
                      className={classes["contact-name"]}
                    >
                      {[...filteredContactData]
                        .splice(3, 6)
                        .map((data, index) =>
                          index > 0 ? (
                            <span key={data}>
                              &nbsp;|&nbsp;{contactData[`${data}1`]}
                            </span>
                          ) : (
                            <span key={data}>{contactData[`${data}1`]}</span>
                          )
                        )}
                    </td>
                  </tr>
                )}
              </Fragment>
            </table>
          </td>
        </tr>
      </Fragment>
    );
  }
  if (designData.layout === 7) {
    return (
      <Fragment>
        {generalData?.name && (
          <tr>
            <td
              style={{
                fontSize: fontSizeLarger,
                fontFamily,
                color: designData.tempColor,
                padding: "0px 0px 5px",
                fontWeight: "bold",
              }}
            >
              {generalData.name}
            </td>
          </tr>
        )}
        {(generalData?.position ||
          generalData?.company ||
          generalData?.department) && (
          <Fragment>
            <tr>
              <td
                style={{
                  fontSize,
                  fontFamily,
                  padding: "0px 0px 0px",
                  color: designData.textColor,
                }}
              >
                {generalData.position && `${generalData.position}`}
                {generalData.position &&
                  (generalData.company || generalData.department) &&
                  ","}
              </td>
            </tr>
            <tr>
              <td
                style={{
                  fontSize,
                  fontFamily,
                  padding: "0px 0px 0px",
                  color: designData.textColor,
                }}
              >
                {generalData.company && `${generalData.company}`}
                {generalData.company && generalData.department && ","}
              </td>
            </tr>
            <tr>
              <td
                style={{
                  fontSize,
                  fontFamily,
                  padding: "0px 0px 8px 0px",
                  color: designData.textColor,
                }}
              >
                {generalData.department && `${generalData.department} `}
              </td>
            </tr>
          </Fragment>
        )}
        <tr>
          <td
            style={{
              fontSize,
              fontFamily,
              padding: 0,
            }}
          >
            <table cellPadding="0">
              {filteredContactData.map((data) => (
                <tr key={data}>
                  <Fragment>
                    <td
                      // valign="center"
                      style={{
                        // color: designData.tempColor,
                        verticalAlign: "center",
                        fontSize,
                        fontFamily,
                        padding: "0px 5px 0px 0px",
                        fontWeight: "bold",
                      }}
                      className={classes["contact-name"]}
                    >
                      <img
                        // width="20"
                        width={+designData.fontSize + 5}
                        height={+designData.fontSize + 5}
                        src={`../../../../../images/${data}.jpg`}
                        alt={data}
                      />
                    </td>
                    <td style={{ fontSize, fontFamily }}>
                      {contactData[`${data}1`]}
                    </td>
                  </Fragment>
                </tr>
              ))}
            </table>
          </td>
        </tr>
      </Fragment>
    );
  }
  if (designData.layout === 8) {
    return (
      <Fragment>
        {generalData?.name && (
          <tr>
            <td
              style={{
                fontSize: fontSizeLarger,
                fontFamily,
                color: designData.tempColor,
                padding: "0px 0px 0px",
                fontWeight: "bold",
              }}
            >
              {generalData.name}
            </td>
          </tr>
        )}
        {(generalData?.position ||
          generalData?.company ||
          generalData?.department) && (
          <tr>
            <td
              style={{
                fontSize,
                fontFamily,
                padding: "0px 0px 10px",
                color: designData.textColor,
                // width: "5000px",
              }}
            >
              {generalData.position && `${generalData.position}`}
              {generalData.position &&
                (generalData.company || generalData.department) &&
                ", "}
              {generalData.company && `${generalData.company}`}
              {generalData.company && generalData.department && ", "}
              {generalData.department && `${generalData.department} `}
            </td>
          </tr>
        )}
        <tr>
          <td
            style={{
              fontSize,
              fontFamily,
              padding: 0,
            }}
          >
            <table cellPadding="0">
              {filteredContactData.map((data) => (
                <tr key={data}>
                  <Fragment>
                    <td
                      // valign="center"
                      style={{
                        // color: designData.tempColor,
                        verticalAlign: "center",
                        fontSize,
                        fontFamily,
                        padding: "1px 5px 0px 0px",
                        fontWeight: "bold",
                      }}
                      className={classes["contact-name"]}
                    >
                      <img
                        // width="20"
                        width={+designData.fontSize + 5}
                        src={`../../../../../images/${data}.jpg`}
                        alt={data}
                      />
                    </td>
                    <td
                      style={{
                        fontSize,
                        fontFamily,
                        color: designData.textColor,
                      }}
                    >
                      {contactData[`${data}1`]}
                    </td>
                  </Fragment>
                </tr>
              ))}
            </table>
          </td>
        </tr>
      </Fragment>
    );
  }
  if (designData.layout === 9) {
    return (
      <Fragment>
        {generalData?.name && (
          <tr>
            <td
              style={{
                fontSize: fontSizeLarger,
                fontFamily,
                color: designData.tempColor,
                padding: "0px 0px 0px",
                fontWeight: "bold",
              }}
            >
              {generalData.name}
            </td>
          </tr>
        )}
        {(generalData?.position ||
          generalData?.company ||
          generalData?.department) && (
          <tr>
            <td
              style={{
                fontSize,
                fontFamily,
                padding: "0px 0px 10px",
                borderBottom: `solid 2px ${designData.tempColor}`,
                color: designData.textColor,
              }}
            >
              {generalData.position && `${generalData.position}`}
              {generalData.position &&
                (generalData.company || generalData.department) &&
                ", "}
              {generalData.company && `${generalData.company}`}
              {generalData.company && generalData.department && ", "}
              {generalData.department && `${generalData.department} `}
            </td>
          </tr>
        )}
        <tr>
          <td
            style={{
              fontSize,
              fontFamily,
              padding: 0,
            }}
          >
            <table cellPadding="0">
              <Fragment>
                <tr valign="middle">
                  <td
                    valign="center"
                    nowrap
                    style={{
                      color: designData.tempColor,
                      fontSize,
                      fontFamily,
                      padding: "10px 5px 8px 0px",
                      fontWeight: "bold",
                      whiteSpace: "nowrap",
                    }}
                    className={classes["contact-name"]}
                  >
                    <p style={{ margin: "0.1px", lineHeight: "1" }}>
                      <span>
                        {[...filteredContactData].splice(0, 3).map((data) => (
                          <Fragment>
                            <span>
                              <img
                                width={+designData.fontSize}
                                // style={{ verticalAlign: "-5px" }}
                                src={`../../../../../images/${data}.jpg`}
                                alt={data}
                              />
                            </span>
                            &nbsp;
                            <span key={data}>{contactData[`${data}1`]}</span>
                            &nbsp;&nbsp;
                          </Fragment>
                        ))}
                      </span>
                    </p>
                  </td>
                </tr>
                {filteredContactData.length > 3 && (
                  <tr>
                    <td
                      valign="middle"
                      style={{
                        paddingTop: "5px",
                        paddingBottom: "10px",
                        color: designData.tempColor,
                        fontSize,
                        fontFamily,
                        padding: "0px 5px 0px 0px",
                        fontWeight: "bold",
                      }}
                      className={classes["contact-name"]}
                    >
                      <p
                        valign="middle"
                        style={{ margin: "0.1px", lineHeight: "1" }}
                      >
                        {[...filteredContactData].splice(3, 6).map((data) => (
                          <Fragment>
                            <span>
                              <img
                                width={+designData.fontSize}
                                // style={{ verticalAlign: "-5px" }}
                                src={`../../../../../images/${data}.jpg`}
                                alt={data}
                              />
                            </span>
                            &nbsp;
                            <span key={data}>{contactData[`${data}1`]}</span>
                            &nbsp;&nbsp;
                          </Fragment>
                        ))}
                      </p>
                    </td>
                  </tr>
                )}
              </Fragment>
            </table>
          </td>
        </tr>
      </Fragment>
    );
  }
  if (designData.layout === 10) {
    return (
      <Fragment>
        {(generalData?.position ||
          generalData?.company ||
          generalData?.department ||
          generalData?.name) && (
          <tr
          // style={{ backgroundColor: designData.tempColor }}
          >
            <td
              style={
                {
                  // fontSize,
                  // fontFamily,
                  // fontWeight: "bold",
                  // padding: "15px 0px 0px 10px",
                  // color: designData.textColor,
                }
              }
            >
              <table
                cellpadding="0"
                cellspacing="0"
                width="100%;"
                // height="130"
                style={{
                  width: "100%",
                  backgroundColor: designData.tempColor,
                  lineHeight: "1.2",
                  // height: "130px",
                }}
              >
                <tbody>
                  <tr>
                    <td
                      style={{
                        padding: "60px 12px 12px",
                        verticalAlign: "bottom",
                        lineHeight: "1.2",
                      }}
                      color="#ffffff"
                    >
                      {generalData.position && (
                        <span
                          style={{
                            colorScheme: "light only",
                            fontSize,
                            fontFamily,
                            letterSpacing: "0px",
                            color: "#ffffff",
                            textTransform: "initial",
                          }}
                        >
                          {generalData.position}
                          {(generalData.company || generalData.department) &&
                            ", "}
                        </span>
                      )}
                      {generalData.company && (
                        <span
                          data-acs="company"
                          class="ws-tpl-company"
                          style={{
                            colorScheme: "light only",
                            fontSize,
                            fontFamily,
                            letterSpacing: "0px",
                            color: "#ffffff",
                            textTransform: "initial",
                          }}
                        >
                          {generalData.company}
                          {generalData.department && ", "}
                        </span>
                      )}
                      {generalData.department && (
                        <span
                          data-acs="company"
                          class="ws-tpl-company"
                          style={{
                            colorScheme: "light only",
                            fontSize,
                            fontFamily,
                            letterSpacing: "0px",
                            color: "#ffffff",
                            textTransform: "initial",
                          }}
                        >
                          {generalData.department}
                        </span>
                      )}
                      <br />
                      {generalData?.name && (
                        <Fragment>
                          <span
                            data-acs="name"
                            class="ws-tpl-name"
                            style={{
                              colorScheme: "light only",
                              fontSize: fontSizeLargest,
                              fontFamily,
                              fontWeight: "bold",
                              color: "#ffffff",
                              textTransform: "initial",
                            }}
                          >
                            {generalData.name}
                          </span>
                          <br />
                        </Fragment>
                      )}
                    </td>
                  </tr>
                </tbody>
              </table>
              {/* {generalData.position && `${generalData.position}`}
              {generalData.position &&
                (generalData.company || generalData.department) &&
                ", "}
              {generalData.company && `${generalData.company}`}
              {generalData.company && generalData.department && ", "}
              {generalData.department && `${generalData.department} `} */}
            </td>
          </tr>
        )}
        {/* {generalData?.name && (
          <tr>
            <td
              style={{
                fontSize: fontSizeLargest,
                fontFamily,
                color: designData.textColor,
                padding: "0px 0px 10px 10px",
                fontWeight: "bold",
                backgroundColor: designData.tempColor,
              }}
            >
              {generalData.name}
            </td>
          </tr>
        )} */}

        <tr>
          <td
            style={{
              fontSize,
              fontFamily,
              padding: 0,
            }}
          >
            <table cellPadding="0">
              <Fragment>
                <tr valign="middle">
                  <td
                    valign="center"
                    nowrap
                    style={{
                      color: designData.textColor,
                      fontSize,
                      fontFamily,
                      padding: "10px 5px 8px 0px",
                      fontWeight: "bold",
                      whiteSpace: "nowrap",
                    }}
                    className={classes["contact-name"]}
                  >
                    <p style={{ margin: "0.1px", lineHeight: "1" }}>
                      <span>
                        {[...filteredContactData].splice(0, 3).map((data) => (
                          <Fragment>
                            &nbsp;
                            <span key={data}>{contactData[`${data}1`]}</span>
                            &nbsp;&nbsp;
                          </Fragment>
                        ))}
                      </span>
                    </p>
                  </td>
                </tr>
                {filteredContactData.length > 3 && (
                  <tr>
                    <td
                      valign="middle"
                      style={{
                        paddingTop: "5px",
                        paddingBottom: "10px",
                        color: designData.textColor,
                        fontSize,
                        fontFamily,
                        padding: "0px 5px 0px 0px",
                        fontWeight: "bold",
                      }}
                      className={classes["contact-name"]}
                    >
                      <p
                        valign="middle"
                        style={{ margin: "0.1px", lineHeight: "1" }}
                      >
                        {[...filteredContactData].splice(3, 6).map((data) => (
                          <Fragment>
                            &nbsp;
                            <span key={data}>{contactData[`${data}1`]}</span>
                            &nbsp;&nbsp;
                          </Fragment>
                        ))}
                      </p>
                    </td>
                  </tr>
                )}
              </Fragment>
            </table>
          </td>
        </tr>
      </Fragment>
    );
  }
  if (designData.layout === 11) {
    return (
      <Fragment>
        {generalData?.name && (
          <tr>
            <td
              style={{
                fontSize: fontSizeLarger,
                fontFamily,
                color: designData.tempColor,
                padding: "0px 0px 5px",
                fontWeight: "bold",
              }}
            >
              {generalData.name}
            </td>
          </tr>
        )}
        {(generalData?.position ||
          generalData?.company ||
          generalData?.department) && (
          <tr>
            <td
              style={{
                fontSize,
                fontFamily,
                padding: "0px 0px 10px",
                color: designData.textColor,
                borderBottom: `solid 2px ${designData.tempColor}`,
              }}
            >
              {generalData.position && `${generalData.position}`}
              {generalData.position &&
                (generalData.company || generalData.department) &&
                ", "}
              {generalData.company && `${generalData.company}`}
              {generalData.company && generalData.department && ", "}
              {generalData.department && `${generalData.department} `}
            </td>
          </tr>
        )}
        <tr>
          <td
            style={{
              fontSize,
              fontFamily,
              padding: 0,
              padding: "10px 0px",
            }}
          >
            <table cellPadding="0">
              {filteredContactData.map((data) => (
                <tr key={data}>
                  <Fragment>
                    <td
                      // valign="center"
                      style={{
                        // color: designData.tempColor,
                        verticalAlign: "center",
                        fontSize,
                        fontFamily,
                        padding: "3px 5px 0px 0px",
                        fontWeight: "bold",
                      }}
                      className={classes["contact-name"]}
                    >
                      <img
                        // width="20"
                        width={+designData.fontSize + 5}
                        src={`../../../../../images/${data}.jpg`}
                        alt={data}
                      />
                    </td>
                    <td
                      style={{
                        fontSize,
                        fontFamily,
                        color: designData.textColor,
                      }}
                    >
                      {contactData[`${data}1`]}
                    </td>
                  </Fragment>
                </tr>
              ))}
            </table>
          </td>
        </tr>
      </Fragment>
    );
  }
  if (designData.layout === 12) {
    return (
      <Fragment>
        {generalData?.name && (
          <tr>
            <td
              style={{
                fontSize: fontSizeLarger,
                fontFamily,
                color: designData.tempColor,
                padding: "0px 0px 5px",
                fontWeight: "bold",
              }}
            >
              {generalData.name}
            </td>
          </tr>
        )}
        {(generalData?.position ||
          generalData?.company ||
          generalData?.department) && (
          <tr>
            <td
              style={{
                fontSize,
                fontFamily,
                padding: "0px 0px 10px",
                color: designData.textColor,
                borderBottom: `solid 2px ${designData.tempColor}`,
              }}
            >
              {generalData.position && `${generalData.position}`}
              {generalData.position &&
                (generalData.company || generalData.department) &&
                ", "}
              {generalData.company && `${generalData.company}`}
              {generalData.company && generalData.department && ", "}
              {generalData.department && `${generalData.department} `}
            </td>
          </tr>
        )}
        <tr>
          <td
            style={{
              fontSize,
              fontFamily,
              padding: 0,
              padding: "10px 0px",
            }}
          >
            <table cellPadding="0">
              {filteredContactData.map((data) => (
                <tr key={data}>
                  <Fragment>
                    <td
                      // valign="center"
                      style={{
                        // color: designData.tempColor,
                        verticalAlign: "center",
                        fontSize,
                        fontFamily,
                        padding: "3px 5px 0px 0px",
                        fontWeight: "bold",
                      }}
                      className={classes["contact-name"]}
                    >
                      <img
                        // width="20"
                        width={+designData.fontSize + 5}
                        src={`../../../../../images/${data}.jpg`}
                        alt={data}
                      />
                    </td>
                    <td
                      style={{
                        fontSize,
                        fontFamily,
                        color: designData.textColor,
                      }}
                    >
                      {contactData[`${data}1`]}
                    </td>
                  </Fragment>
                </tr>
              ))}
            </table>
          </td>
        </tr>
      </Fragment>
    );
  }
};

export default Text;
