import { child, get, getDatabase, ref } from "firebase/database";
import { designSliceActions } from "./design-slice";
import { generalSliceActions } from "./general-slice";
import { imageSliceActions } from "./image-slice";
import { socialSliceActions } from "./social-slice";

export const setDataBySid = (uid, sid, setDisplayFields) => {
  return async (dispatch) => {
    // dispatch(generalSliceActions.setLoading({ loading: true }));

    const dbRef = ref(getDatabase());
    const response = await get(
      child(dbRef, `users/${uid}/signatureData/${sid}`)
    );
    const {
      generalData,
      contactData,
      imageData,
      socialData,
      designData,
      displayFields,
    } = response.val();

    dispatch(generalSliceActions.setAllData({ generalData, contactData }));
    dispatch(imageSliceActions.setLogoWidth({ width: imageData.logoWidth }));
    dispatch(socialSliceActions.setAllData({ ...socialData }));
    dispatch(designSliceActions.setAllData({ ...designData }));

    if (displayFields) {
      setDisplayFields(displayFields);
    } else {
      setDisplayFields([]);
    }

    // console.log(displayFields);
    // dispatch(generalSliceActions.setLoading({ loading: false }));

    // console.log(response.val());
  };
};

export const resetSignatureData = () => {
  return async (dispatch) => {
    dispatch(generalSliceActions.resetState());
    dispatch(imageSliceActions.resetState());
    dispatch(socialSliceActions.resetState());
    dispatch(designSliceActions.resetState());
  };
};
