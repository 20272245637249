import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { imageSliceActions } from "../../../../store/image-slice";
import { Button, Typography } from "@mui/material";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import CancelIcon from "@material-ui/icons/Cancel";

import classes from "./Images.module.css";
import { Fragment } from "react";
import CropModal from "./CropModal/CropModal";

const Images = () => {
  const dispatch = useDispatch();
  const imageData = useSelector((state) => state.image);
  const [image, setImage] = useState();

  const onImageChange = (event) => {
    setImage(...event.target.files);
  };

  useEffect(() => {
    if (image) {
      const newImageUrl = URL.createObjectURL(image);
      dispatch(
        imageSliceActions.setImageURL({ url: newImageUrl, image: image })
      );
    }
  }, [image, dispatch]);

  const removeImageHandler = () => {
    setTimeout(() => {
      dispatch(imageSliceActions.setImageURL({ url: "", image: null }));
    }, 100);
  };

  return (
    <div className={classes.main}>
      <Typography color="#2C4166" paragraph fontSize={18} gutterBottom>
        Logo / Photo
      </Typography>
      <Button
        variant="contained"
        component="label"
        sx={{
          height: "11rem",
          width: "11rem",
          borderRadius: "15px",
          display: "flex",
          flexDirection: "column",
          backgroundColor: "white",
          color: "#2C4166",
          border: "2px solid #E6EDF5",
          textTransform: "capitalize",
        }}
      >
        {imageData.imageURL ? (
          <Fragment>
            <CancelIcon fontSize="large" />
            <p className={classes["button-text"]} style={{ color: "#2c4166" }}>
              Remove Image
            </p>
            <input type="button" hidden onClick={removeImageHandler} />
          </Fragment>
        ) : (
          <Fragment>
            <CloudUploadIcon fontSize="large" />
            <p className={classes["button-text"]} style={{ color: "#2c4166" }}>
              Choose File
            </p>
            <input
              type="file"
              hidden
              accept="image/*"
              onChange={onImageChange}
            />
          </Fragment>
        )}
      </Button>
      <div
        style={{
          marginTop: "3rem",
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <label htmlFor="img-width">Image Width</label>
        <input
          style={{ width: "50%" }}
          type="range"
          min="20"
          max="200"
          step="1"
          defaultValue="100"
          value={imageData.width}
          // onMouseUp
          onInput={(event) => {
            dispatch(
              imageSliceActions.setLogoWidth({ width: +event.target.value })
            );
          }}
        />
      </div>
      {imageData.imageURL && <CropModal />}
    </div>
  );
};

export default Images;
