import React from "react";
import ReactDOM from "react-dom/client";
import { signUp } from "./firebase";

import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import store from "./store";
import { BrowserRouter } from "react-router-dom";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <React.StrictMode>
  <BrowserRouter>
    <Provider store={store}>
      <App />
    </Provider>
  </BrowserRouter>
  // </React.StrictMode>
);

reportWebVitals();

// console.log(
//   renderToString(
//     <Provider store={store}>
//       <PreviewStatic />
//     </Provider>
//   )
// );

// const response = ReactDOMServer.renderToReadableStream(
//   <Provider store={store}>
//     <Preview />
//   </Provider>
// )
//   .then((response) => response.allReady)
//   .then((response) => console.log(response));
